import { IoMdCube as ServersIcon } from "react-icons/io";
import { Panel, PanelHeader, TabContent, Tabs } from "../../components";
import ServerList from "./ServerList";

const Servers = () => {
  return (
    <div className="server-container">
      <Panel>
        <PanelHeader
          PanelIcon={ServersIcon}
          title="Server Manager"
          subtitle="Mirth Connect"
        />
      </Panel>

      <Tabs>
        <TabContent label="Info">
          <ServerList />
        </TabContent>
      </Tabs>
    </div>
  );
};

export default Servers;
