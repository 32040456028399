import { ImSpinner9 as SpinIcon, ImSpinner2 as Spinner } from "react-icons/im";
import { Repography } from ".";
import "../assets/css/loader.css";

const LoadSpinner = ({ medium, large, label }) => {
  const key = medium ? "medium" : large ? "large" : "default";
  const currSize = { medium: 32, large: 48, default: 24 }[key];

  return (
    <div className="spinner">
      <Spinner className="anime" size={currSize} />
      <span>{label}</span>
    </div>
  );
};

const LoadThreeSpins = () => {
  return <div className="loader"></div>;
};

const LoadingOverlay = ({ children, active }) => {
  return (
    <div className="relative">
      {active && (
        <>
          <section className="loader absolute top-1/2 left-1/2 z-20"></section>
          <div className="absolute w-full h-full bg-white bg-opacity-60 z-30 overflow-x-hidden overflow-y-auto outline-none" />
        </>
      )}
      <main>{children}</main>
    </div>
  );
};

function loadPage() {
  return (
    <div className="relative flex flex-col items-center justify-center h-full space-y-1">
      <div className="flex space-x-2">
        <SpinIcon
          className="animate-bounce opacity-40 text-secondary"
          size={40}
        />
        <Repography h6 center text="Loading..." />
      </div>
      <Repography center text="This may take a few seconds to refresh." />
    </div>
  );
}

export default LoadSpinner;
export { LoadingOverlay, LoadThreeSpins, loadPage };
