import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useOnce, useRefetch } from ".";
import { Urls } from "../axios";

const initialState = {
  page: 1,
  pageSize: 24,
  status: "All",
  filter: {},
  connectors: [],
  servers: [],
};

const filterReducer = (state, { type, payload }) => {
  switch (type) {
    case "connector":
      return {
        ...state,
        connectors: payload,
      };
    case "server":
      const { servers, ...noServers } = state;
      return {
        ...noServers,
        servers: {
          ...servers,
          [payload]: !servers[payload],
        },
      };
    case "limit":
      if (payload === undefined) return state;

      const { totalCount, limit } = payload;
      const lastExpected = Math.ceil(totalCount / limit);

      return {
        ...state,
        pageSize: limit,
        page: state.page > lastExpected ? lastExpected : state.page,
      };
    case "term":
      const { searchTerm, ...prev } = state;
      if (payload.value) prev.searchTerm = payload;
      return prev;
    case "filter":
      const { field, operator, value } = payload;
      return {
        ...state,
        filter: { ...state.filter, [field]: { operator, value } },
      };
    case "page":
    case "status":
      return { ...state, [type]: payload };
    default:
      return state;
  }
};

const useFilter = (queryKey, state = initialState) => {
  const [filters, dispatch] = useReducer(filterReducer, state);
  const [{ results, total }, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const { fetchQuery, invalidateQuery } = useRefetch([queryKey]);
  const isTriggered = useRef(false);

  const refetch = useCallback(() => {
    fetchQuery("POST", Urls.search[queryKey], filters).then(
      ({ data, error }) => {
        if (data) {
          const { entry, total } = data;
          const { results } = entry || {};
          setData({ results: results || [], total });
          if (window.scrollY > 300)
            window.scrollTo({ top: 300, behavior: "smooth" });
        } else if (error) {
          console.log(error);
        }

        isTriggered.current = true;
        setIsLoading(false);
      }
    );
  }, [fetchQuery, filters, queryKey]);

  useOnce(refetch);

  useEffect(() => {
    if (isTriggered.current) {
      setIsLoading(true);

      try {
        if (queryKey) void refetch();
      } finally {
        isTriggered.current = false;
      }
    }
  }, [filters, queryKey, refetch]);

  return [dispatch, { results, total, isLoading, refetch, invalidateQuery }];
};

export default useFilter;
