import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useMemo, useReducer } from "react";
import { LoadSpinner, Toast } from "../../components";
import api, { headers } from "../../store/axios";
import { useAxiosPrivate } from "../../store/hooks";
import GroupImportForm from "./GroupImportForm";
import { MirthLoginForm } from "./MirthLoginForm";

const reducer = (state, { type, payload }) => {
  switch (type) {
    case "data":
      const { data, session } = payload;
      return { data, session, loaded: true };
    case "error":
      return {
        loaded: state.loaded,
        data: state.data,
        error: payload,
        session: state.session,
      };
    case "fetch":
      return { loading: true, message: payload };
    case "staging":
      return { ...state, importing: false, stagingId: payload };
    default:
      return state;
  }
};

const SignInForm = ({ server, state, dispatch }) => {
  return (
    <>
      <div className="flex-j trans300">
        {state.loading ? (
          <LoadSpinner medium label={state.message} />
        ) : state.error ? (
          <Toast warning message={state.error} />
        ) : null}
      </div>
      <section className={`${state.loaded && "hidden-scale"}`}>
        <MirthLoginForm
          serverName={server}
          title="Download Channel Groups"
          loading={state.loading}
          onAuthorize={dispatch}
        />
      </section>
    </>
  );
};

//  Import Mirth Channel Groups and update repository records
//  through data ingestion interface engine servers.
//
//  Quick Note:
//  ----------
//    This process is used to extract data from various Mirth Connect servers,
//    transform it into models suitable for staging and loading
//    into the EIIR repository database.

const DownloadGroupsForm = ({ serverId, serverName, onClose }) => {
  const [state, dispatch] = useReducer(reducer, {});
  const queryClient = useQueryClient();
  const axiosPrivate = useAxiosPrivate();

  const serverUrlPrefix = useMemo(
    () => `/mirth/servers/${serverId}`,
    [serverId]
  );

  const handleCancel = useCallback(async () => {
    queryClient.cancelQueries({ queryKey: ["groups"] });
    await axiosPrivate.post(`${serverUrlPrefix}/logout`, {
      session: state.session,
    });
    // eslint-disable-next-line
  }, [serverUrlPrefix, queryClient, state.session]);

  const handleAuthorize = useCallback(
    async (username, password) => {
      let type = "data",
        payload;

      try {
        dispatch({ type: "fetch", payload: "Logging into Mirth instance" });
        const { data } = await queryClient.fetchQuery({
          queryKey: ["channelgroups"],
          queryFn: async ({ signal }) =>
            await api.post(
              `${serverUrlPrefix}/channelgroups`,
              { username, password },
              {
                headers,
                withCredentials: true,
                signal,
              }
            ),
        });

        const { session_id, groups } = data || {};
        payload = { session: session_id, data: groups };
      } catch ({ response }) {
        const { detail } = response?.data || {};
        const statusCode = response?.status;

        type = "error";
        payload =
          statusCode === 404
            ? "Failed to fetch Channels: Resource not found."
            : statusCode === 403
            ? `Authorization failed: ${detail}`
            : statusCode === 504
            ? "Dowloading Groups task timed out."
            : detail;
      } finally {
        dispatch({ type, payload });
      }
    },
    // eslint-disable-next-line
    [serverUrlPrefix]
  );

  const mutationList = useMemo(
    () => ({
      dispatch,
      stage: async (selectedGroups) =>
        await axiosPrivate.post(`${serverUrlPrefix}/staging`, {
          session: state.session,
          selectedGroups,
        }),
      onCancel: handleCancel,
      onClose,
    }),
    // eslint-disable-next-line
    [state, serverUrlPrefix, onClose, handleCancel]
  );

  return (
    <div className="drawer-form pb-3">
      {state.loaded ? (
        <GroupImportForm groups={state.data || []} mutations={mutationList} />
      ) : (
        <SignInForm
          server={serverName}
          state={state}
          dispatch={handleAuthorize}
        />
      )}
    </div>
  );
};

export default DownloadGroupsForm;
