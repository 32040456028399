import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { FiCheckCircle as SuccessIcon } from "react-icons/fi";
import { LuFolderSync as UpdateIcon } from "react-icons/lu";
import { Button } from "../../components";
import { useAxiosPrivate, useToast } from "../../store/hooks";
import useIsMounted from "../../store/hooks/use-ismounted";

const ServerStatus = {
  Ready: "R",
  Active: "A",
  Inactive: "I",
  Staged: "S",
};

export const RepositoryBuild = ({ server, onClose }) => {
  const [complete, setComplete] = useState(false);
  const { element, warn } = useToast();
  const client = useQueryClient();
  const axiosPrivate = useAxiosPrivate();
  const isMounted = useIsMounted();

  const buildMutation = useMutation({
    mutationFn: async () =>
      await axiosPrivate.post(`/mirth/servers/${server.serverId}/feeds`),
    onSuccess: () => {
      client.invalidateQueries({ queryKey: ["feeds"] });
      setComplete(true);
    },
    onError: ({ response }) => {
      const { detail } = response.data;
      warn(detail);
    },
    retry: false,
  });

  useEffect(() => {
    if (isMounted()) {
      if (server.status !== ServerStatus.Active)
        warn(
          <>
            <p>There are uncommitted work pending.</p>
            <p>Please try again later!</p>
          </>
        );
    }
  }, [isMounted, warn, server]);

  return (
    <div className="flex-ij-col space-y-3 mb-4">
      {complete ? (
        <>
          <span className="text-green-600">
            <SuccessIcon size={48} />
          </span>
          <p className="font-semibold">Build completed successfully.</p>
          <Button content="Close Window" onClick={onClose} />
        </>
      ) : (
        <>
          <span className="text-secondary">
            <UpdateIcon size={48} />
          </span>
          <div className="text-center font-semibold">
            <span className="text-sm">Server Synchronization</span>
            <p>{server.name}</p>
          </div>

          {server.status === ServerStatus.Active && (
            <>
              <p className="text-center leading-tight">
                Click the button below to sync the EIIR Repository
                <br />
                <span>with the latest changes</span>
              </p>

              <Button
                content="Build Repository"
                onClick={() => buildMutation.mutate()}
                loading={buildMutation.isLoading}
                disabled={server.status !== ServerStatus.Active}
              />
            </>
          )}

          {element}
        </>
      )}
    </div>
  );
};
