import { useMemo, useState } from "react";
import { CgArrowsExchangeAltV as ExFilterIcon } from "react-icons/cg";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdStream as SlIcon } from "react-icons/md";
import { IconButton, StackedAvatar } from "../../components";
import QueryFilter, { FilterPanel } from "../../components/filters/QueryFilter";
import { useCommon, useTable } from "../../store/hooks";
import { ServiceSummary } from "./ServiceSummary";

export const ServiceList = ({ client, dispatch }) => {
  const [service, setService] = useState(null);
  const { feeds, exchangeTypes } = useCommon();

  const feedOptions = useMemo(
    () => Object.values(feeds || {}).map(({ serviceline }) => serviceline),
    [feeds]
  );

  const typeOptions = useMemo(
    () => ({
      field: "exchange",
      id: "filter-ex-form",
      title: "Filter by exchange type",
      subtitle: "Find a facility that has a specific data exchange type.",
      values: Object.values(exchangeTypes).map(({ type }) => type),
    }),
    [exchangeTypes]
  );

  const serviceConfig = useMemo(
    () => [
      {
        label: "Service Id",
        render: (service) => service.serviceId,
        align: "text-center",
      },
      {
        label: "Location",
        render: (service) => service.location.name,
        sortValue: (service) => service.location.name,
      },
      {
        label: "COCID",
        render: (service) => (
          <span className="text-purple-700">{service.cocid}</span>
        ),
      },
      {
        label: "Connect Id",
        render: (service) => (
          <span className="text-violet-600">{service.connectId}</span>
        ),
        align: "text-center",
      },
      {
        label: "Data Exchange",
        render: (service) => (
          <div className="leading-tight">
            <span>{service.mirthfeed.exchangeType}</span>
          </div>
        ),
      },
      {
        label: "Services",
        render: (service) => (
          <section>
            <StackedAvatar
              labels={service.subservices.map((s) => s.shortname)}
            />
          </section>
        ),
      },
      {
        key: "Action",
        render: (service) => (
          <IconButton
            title="View Interface Data flow"
            icon={<Icon size={18} />}
            sx={{ margin: "5px 10px" }}
            onClick={() => setService(service)}
          />
        ),
        align: "text-center",
      },
    ],
    []
  );

  const { table } = useTable(client, serviceConfig, dispatch);
  const renderServices = service ? (
    <ServiceSummary service={service} onClose={() => setService(null)} />
  ) : (
    <>
      <FilterPanel>
        <QueryFilter
          id="servicename-filter"
          label="facility name"
          dispatch={dispatch}
        />
        <QueryFilter
          type="select"
          id="sl-filter"
          label="service line"
          icon={SlIcon}
          options={{
            field: "service",
            id: "sfilter-sl-form",
            title: "Filter by service line",
            subtitle: "Find location that has a specific line of service.",
            values: feedOptions,
          }}
          dispatch={dispatch}
        />
        <QueryFilter
          type="select"
          id="ex-filter"
          label="data exchange"
          icon={ExFilterIcon}
          options={typeOptions}
          dispatch={dispatch}
        />
      </FilterPanel>
      {table}
    </>
  );

  return <div className="w-full">{renderServices}</div>;
};
