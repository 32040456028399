import { Fragment } from "react";
import Skeleton from "./Skeleton";

const ColSpan = ({ key, className, size, children }) => {
  return (
    <tr className={className} key={key}>
      <td className="p-3" colSpan={size}>
        {children}
      </td>
    </tr>
  );
};

const TableRow = ({ config, record, loose }) => {
  const cells = config.map((column, i) => {
    const currClass = [column.align, loose ? "py-[7px]" : "py-0"];

    return (
      <td
        key={i}
        className={currClass.join(" ")}
        style={{ width: column.width }}
      >
        {column.render(record)}
      </td>
    );
  });

  return <tr className="trow">{cells}</tr>;
};

/**
 * Table.js offers all the basic features necessary
 * for displaying data on any simple tabular format
 * and provides features like search, filtering, sorting, and pagination.
 * @param {*} param0
 * @returns
 */
const Table = ({ sx, config, dataSource, label, loading, ...props }) => {
  if (!config) return;

  const headers = config.map((column) => (
    <Fragment key={column.label || column.key}>{column.header()}</Fragment>
  ));

  const rows =
    dataSource && dataSource.length > 0 ? (
      dataSource?.map((row, i) => (
        <TableRow
          key={`${row.channelId || row.uuid}${i}`}
          record={row}
          config={config}
          {...props}
        />
      ))
    ) : (
      <ColSpan size={config.length} className="trow">
        <span className="norow">{label || "No matching records found."}</span>
      </ColSpan>
    );

  const isVertical =
    config.verticalOnly === undefined || config.verticalOnly === true;

  return (
    <div
      id="repo-scroll"
      className={`table-wrapper${isVertical ? " vertical" : ""}`}
      style={sx}
    >
      <table className="table-repo sticky">
        <thead className={config.dark && "thead-dark"}>
          <tr key="header">{headers}</tr>
        </thead>
        <tbody>
          {loading ? (
            <ColSpan size={headers.length}>
              <Skeleton list={{ rows: 6, cols: 4 }} />
            </ColSpan>
          ) : (
            rows
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
