import { useDagre } from "../../store/hooks";
import { BadgeIndicator, Tabs, TabContent, Timeline } from "../../components";
import { BsBoxArrowInRight as CheckIcon } from "react-icons/bs";
import { TimelineItem } from "../../components/Timeline";
import { renderConnector } from "./config";
import { channelNodes } from "./elements";

export const ChannelSummary = ({ channel }) => {
  const { createLayoutFlow } = useDagre({
    direction: "LR",
    onCreateNodes: (el) => channelNodes(el),
  });
  if (!channel) return;

  const flowR = createLayoutFlow(channel);

  const renderDests = channel.destinations.map((dest) => {
    const [content, connIcon] = renderConnector(dest);
    return (
      <li key={dest.destination_id}>
        <div className="icon">{connIcon}</div>
        <div className="dest">
          <p className="leading-tight">Dest. {dest.destination_id}</p>
          {dest.connector_type}
        </div>
        <div className="w-[260px]">{content}</div>
      </li>
    );
  });

  return (
    <div id="repo-scroll" className="channel-summary">
      <div className="box">
        <div>
          <h3 className="title">{channel.name}</h3>
          <BadgeIndicator
            label={channel.is_enabled ? "enabled" : "disabled"}
            color={channel.is_enabled ? "ok" : "basic"}
          />
        </div>
        <ol>
          {[
            channel.uuid,
            channel.mirthserver.name,
            `${channel.dataformat.name} ${channel.source_connector} ${
              channel.port ? `on port ${channel.port}` : ""
            }`.trim(),
          ].map((value, i) => (
            <li key={i}>
              <CheckIcon size={18} />
              <span>{value}</span>
            </li>
          ))}
        </ol>
        <div className="flex flex-col justify-end mt-4">
          <span>Channel Group: </span>
          <span className="text-sm font-medium text-violet-600">
            {channel.channelgroup?.name}
          </span>
        </div>
        <p className="mt-3 text-gray-600 text-sm">{channel.description}</p>
      </div>

      <Tabs>
        <TabContent label="Destinations">
          <ol className="channel-dest">{renderDests}</ol>
        </TabContent>
        <TabContent label="Channel Flow" className="h-screen w-lg">
          <div className="h-screen w-lg">{flowR}</div>
        </TabContent>
        <TabContent label="Changes History">
          <Timeline sx={{ paddingLeft: 20 }}>
            <TimelineItem
              title="Last Modified"
              date={(channel.last_modified || "").split(".")[0]}
            />
          </Timeline>
        </TabContent>
      </Tabs>
    </div>
  );
};
