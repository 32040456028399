import { memo, useEffect, useMemo, useRef, useState } from "react";
import { BsBoxArrowInRight as CheckIcon } from "react-icons/bs";
import { TbArrowBackUp as BackIcon } from "react-icons/tb";
import {
  Button,
  Card,
  Divider,
  InputField,
  RadioGroup,
  Skeleton,
  TabContent,
  Table,
  Tabs,
} from "../../components";
import { useDagre, useRefetch, useSortableData } from "../../store/hooks";
import { SummaryHeader } from "./SummaryHeader";
import { feedNodes } from "./elements";
import { orgConfig } from "./config";

const FeedSummary = ({ serviceId, onClose }) => {
  const [{ feed, flow }, setFeed] = useState({});
  const { fetchQuery } = useRefetch(["servers", { serviceId }]);
  const isMounted = useRef(false);

  const directionGroups = useMemo(
    () => [
      { value: "inbound", label: "Inbound" },
      { value: "outbound", label: "Outbound" },
      { value: "bi-directional", label: "Bi-Directional" },
    ],
    []
  );

  const { createLayoutFlow } = useDagre({
    direction: "TB",
    onCreateNodes: (el) => feedNodes(el),
  });

  useEffect(() => {
    if (isMounted.current) return;
    isMounted.current = true;

    fetchQuery("GET", `/mirth/feeds/${serviceId}`).then(({ data, error }) => {
      if (data) {
        const flow = createLayoutFlow(data.endpoint);
        setFeed({ feed: data, flow });
      } else if (error) console.log(error);
    });

    // eslint-disable-next-line
  }, [serviceId, createLayoutFlow]);

  const { dataSorted, headerConfig } = useSortableData(
    feed?.location || [],
    orgConfig
  );

  if (feed === null || feed === undefined)
    return <Skeleton list={{ rows: 5, cols: 4 }} />;

  const { managingOrg, category, identifier, provision } = feed || {};
  const renderExtra = Object.entries({
    ctr: feed.active ? "Active" : "Inactive",
    df: `${provision.dataformat} ${category?.hl7}`,
    dx: category?.name,
    hl7: provision?.transport,
  }).map(([k, v]) => (
    <li key={k} className="flex space-x-3 space-y-1 items-center">
      <CheckIcon size={18} />
      <span>{v}</span>
    </li>
  ));

  return (
    <div className="grid grid-cols-1 gap-y-3 w-full">
      <Card>
        <form>
          <div className="flex items-center justify-start font-semibold">
            <Button outline onClick={() => onClose()}>
              <div className="text-primary text-base tracking-tight flex space-x-1 items-center mb-3">
                <BackIcon size={18} />
                <span>Return to list</span>
              </div>
            </Button>
          </div>

          <div className="grid grid-cols-1 mr-5 mb-3 space-y-2">
            <div className="flex space-x-3">
              <span className="flex justify-end w-[15%] mt-2 tracking-tight">
                Data Entry Point:
              </span>
              <div className="w-[85%]">
                <div className="space-y-2">
                  <div className="flex space-x-2">
                    {createControl("channel", feed?.name, "Channel Name", 360)}
                    {createControl(
                      "uuid",
                      identifier?.uuid,
                      "Channel UUID",
                      340
                    )}
                  </div>
                  <div className="flex space-x-2">
                    {createControl(
                      "server",
                      provision?.server,
                      "EVPS Mirth Server",
                      360
                    )}
                    {createControl(
                      "connector",
                      feed?.type,
                      "Connector Type",
                      200
                    )}
                    {createControl("port", provision?.port, "Port", 100)}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex space-x-3 my-2 py-2">
              <span className="flex justify-end w-[15%] items-center tracking-tight">
                Health System:
              </span>
              <div className="w-[85%]">
                {createControl("hs", feed?.healthsystem, null, 360, false)}
              </div>
            </div>
            <div className="flex space-x-3 mt-2">
              <span className="flex justify-end w-[15%] items-center tracking-tight">
                Facility HIS:
              </span>
              <div className="w-[85%]">
                {createControl("his", "", null, 360, false)}
              </div>
            </div>
            <div className="flex space-x-3">
              <span className="w-[15%]"></span>
              <div className="w-[70%]">
                <div className="flex space-x-3 my-2">
                  <div className="space-y-4">
                    <div className="flex flex-nowrap space-x-3">
                      <span className="mt-2 w-2/5 flex justify-end tracking-tight">
                        Downstream System:
                      </span>
                      <div className="w-3/5">
                        <InputField disabled id="ds" width={216} />
                      </div>
                    </div>
                    <div className="flex flex-nowrap space-x-3">
                      <span className="mt-2 w-2/5 flex justify-end tracking-tight">
                        Vendor:
                      </span>
                      <div className="w-3/5">
                        {createControl("vendor", "", null, 216, false)}
                      </div>
                    </div>
                    <div className="flex flex-nowrap space-x-3 w-full mr-5 pr-5">
                      <span className="w-2/5 flex justify-end tracking-tight">
                        Direction:
                      </span>
                      <div className="w-3/5">
                        <RadioGroup
                          disabled
                          name="direction"
                          selectedValue="inbound"
                          group={directionGroups}
                        />
                      </div>
                    </div>
                  </div>

                  <Divider sx={{ marginLeft: 20 }} />

                  <ol>{renderExtra}</ol>
                </div>
              </div>
            </div>
          </div>
        </form>
      </Card>

      <Tabs>
        <TabContent label="Flowchart" className="feed-flow">
          <div className="feed-flow">{flow}</div>
        </TabContent>
        <TabContent label="Managing Organization">
          {managingOrg && (
            <>
              <SummaryHeader
                isHCA={feed.name.includes("HCA")}
                org={managingOrg}
                locations={feed.location}
              />
              <Table
                dataSource={dataSorted}
                config={headerConfig}
                sx={{ maxHeight: "480px" }}
              />
            </>
          )}
        </TabContent>
        <TabContent label="Change History" />
      </Tabs>
    </div>
  );
};

function createControl(id, value, text, size, required = true) {
  return (
    <InputField
      disabled
      required={required}
      id={id}
      helperText={text}
      value={value || ""}
      width={size}
    />
  );
}

export default memo(FeedSummary);
