import { useMutation } from "@tanstack/react-query";
import { useCallback, useMemo, useReducer } from "react";
import { FaRegCircleCheck as CheckIcon } from "react-icons/fa6";
import { MdCloudOff as NoIcon } from "react-icons/md";
import { Button, Checkbox, Divider, Table } from "../../components";
import { useSortableData, useToast } from "../../store/hooks";

function reducer(state, { type, payload }) {
  const count = Object.keys(state.renderGroups).length;
  switch (type) {
    case "all":
      for (let g of Object.values(state.renderGroups)) g.checked = payload;
      return {
        total: payload ? Object.keys(state.renderGroups).length : 0,
        renderGroups: state.renderGroups,
        selectAll: payload,
      };
    case "sole":
      const { id, checked } = payload;
      const curr = { ...state.renderGroups[id], checked };
      const total = state.total + (checked ? 1 : -1);
      return {
        ...state,
        total,
        selectAll: count === total,
        renderGroups: { ...state.renderGroups, [id]: curr },
      };
    default:
      return state;
  }
}

const GroupImportForm = ({ mutations, groups }) => {
  const toast = useToast();
  const [state, dispatch] = useReducer(reducer, groups, (groups) => ({
    renderGroups: groups.reduce((rObj, group) => {
      const { id, name, channels } = group;
      const { count, imported } = channels;
      rObj[id] = { id, checked: false, name, count, imported };
      return rObj;
    }, {}),
    total: 0,
    selectAll: false,
  }));

  const { total, renderGroups, selectAll } = state;

  const totalChannels = useMemo(
    () => groups.reduce((acc, { channels }) => acc + channels.count, 0),
    [groups]
  );

  // Mutation to load channel groups into a staging table
  const stagingMutation = useMutation({
    mutationKey: ["groups"],
    mutationFn: mutations.stage,
    onSuccess: (response) => {
      const { data, status } = response || {};
      if (status === 200) mutations.onClose(data.message);
      else mutations.dispatch({ type: "error", payload: data.message });
    },
    onError: ({ response }) => {
      const { detail } = response?.data || {};
      toast.warn(detail);
    },
  });

  const handleStageImport = useCallback(() => {
    const payload = groups.reduce((obj, g) => {
      if (renderGroups[g.id].checked) obj.push(g);
      return obj;
    }, []);
    stagingMutation.mutate(payload);
    // eslint-disable-next-line
  }, [renderGroups, groups]);

  const apiConfig = useMemo(
    () => [
      {
        key: "Select",
        render: (g) => {
          const checkbox =
            g.count === undefined ? (
              <Checkbox
                key="select-all"
                id="all"
                checked={selectAll}
                onChange={({ target }) =>
                  dispatch({ type: "all", payload: target.checked })
                }
              />
            ) : g.count ? (
              <Checkbox
                key={g.id}
                id={g.id}
                checked={g.checked || false}
                onChange={({ target }) =>
                  dispatch({
                    type: "sole",
                    payload: { id: target.id, checked: target.checked },
                  })
                }
              />
            ) : g.imported === 0 ? (
              <NoIcon color="brown" size={18} />
            ) : null;
          return <section className="pl-2">{checkbox}</section>;
        },
      },
      {
        label: "Channel Groups",
        render: (g) => <p className="py-1">{g.name}</p>,
      },
      {
        label: "Imported",
        render: (g) => `${g.imported}/${g.count}`,
        align: "text-center",
      },
      {
        key: "Status",
        render: (g) => {
          if (g.count > 0) {
            return (
              g.count === g.imported && (
                <CheckIcon
                  className="flex space-x-3 pl-2 text-secondary"
                  size={25}
                />
              )
            );
          }
        },
      },
    ],
    [selectAll]
  );

  const { dataSorted, headerConfig } = useSortableData(
    Object.values(renderGroups),
    apiConfig
  );

  return (
    <div className="drawer-section px-1">
      <main>
        <div className="flex justify-center">{toast.element}</div>
        <div className="tsnw">
          {`${dataSorted.length} Groups [ ${totalChannels} Channels ]`}
        </div>
        <Table
          config={headerConfig}
          dataSource={dataSorted}
          sx={{ height: "80vh" }}
        />
      </main>
      <div className="flexe space-x-4 pb-4 pt-2">
        <span>{total} Groups selected</span>
        <Divider />
        <Button
          id="cancel-btn"
          outline
          content="Cancel"
          onClick={() => {
            mutations.onCancel();
            mutations.onClose();
          }}
        />
        <Button
          id="import-btn"
          content="Stage Groups"
          disabled={!total}
          loading={stagingMutation.isLoading}
          onClick={handleStageImport}
        />
      </div>
    </div>
  );
};

export default GroupImportForm;
