import { useState } from "react";
import { Dropdown, InputField, Option } from "../../components";
import { SubmitButton } from "./SubmitButton";

const values = ["equals", "contains", "starts with", "ends with"].map(
  (option) => <Option key={option.replace(" ", "_")} value={option} />
);

export function OpFilter({ field, label, onApply }) {
  const [inputValue, setInputValue] = useState("");
  const [selection, setSelection] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    onApply({ field, operator: selection, value: inputValue });
    setSelection("");
    setInputValue("");
  };

  return (
    <form
      id="sfilter-name-form"
      className="w-[300px] sdrop"
      onSubmit={handleSubmit}
    >
      <p className="font-semibold">Filter by {label.toLowerCase()}</p>
      <p className="text-neutral-600">
        Find a name that matches a search criteria.
      </p>
      <Dropdown
        value={selection}
        sourceItems={values}
        onChange={(sel) => setSelection(sel)}
      />
      <div className="w-full flex-ij space-x-2 py-0.5 -m-1">
        <span>⤹</span>
        <InputField
          required
          width={320}
          value={inputValue}
          onChange={({ target }) => setInputValue(target.value)}
        />
      </div>

      <SubmitButton enabled={inputValue && selection} />
    </form>
  );
}
