import { useReducer, useEffect } from "react";
import {
  Accordion,
  Button,
  Dropdown,
  LoadSpinner,
  Option,
  ToggleSwitch,
} from "../../../components";
import { TbArrowBackUp as BackIcon } from "react-icons/tb";

const initialState = {
  viewOnly: false,
  preset: false,
  expanders: {},
};

const policyReducer = (state, action) => {
  switch (action.type) {
    case "view":
      return { ...state, viewOnly: action.payload };
    case "expanders":
      const { all, index } = action.payload;
      const currExpanders = all ? { ...state.expanders } : {};
      return {
        ...state,
        expanders: { ...currExpanders, [index]: !state.expanders[index] },
      };
    case "preset":
      return { ...state, preset: action.payload };
    case "reload":
      return { ...initialState, preset: true };
    default:
      return state;
  }
};

export const PolicySection = ({ selection, role, onChange, onBack }) => {
  const [policy, dispatch] = useReducer(policyReducer, initialState);

  const { viewOnly, preset, expanders } = policy;
  const { categories, toggles, editMode } = selection;

  useEffect(() => {
    const curr = Object.entries(toggles).reduce(
      (reduceToggle, [key, toggle]) => {
        reduceToggle[key] = {
          ...toggle,
          allowed: viewOnly
            ? toggle.level > 1
              ? false
              : toggle.allowed
            : true,
        };
        return reduceToggle;
      },
      {}
    );

    onChange(curr);
    // eslint-disable-next-line
  }, [viewOnly]);

  useEffect(() => {
    if (categories) dispatch({ type: "preset", payload: true });
  }, [categories]);

  const handleBack = () => {
    dispatch({ type: "preset", payload: false });
    onBack();
  };

  const handleSwitch = (id, accessLevel, allowed) => {
    if (viewOnly && accessLevel > 1) return;
    onChange({ ...toggles, [id]: { ...toggles[id], allowed } });
  };

  const items = Object.entries(categories || {}).map(([category, actions]) => {
    const values = [];
    for (let action of actions) {
      values.push(
        <div key={action.actionId} className="entry">
          <ToggleSwitch
            id={`toggleSwitch-${action.actionId}`}
            allowed={toggles[action.actionId].allowed}
            onSwitch={(allowed) =>
              handleSwitch(action.actionId, action.accessLevel.id, allowed)
            }
          />
          <div className="leading-tight">
            <span className="text-sm">{action.action}</span>
            <div className="text-xs">tag: {action.tag}</div>
          </div>
        </div>
      );
    }

    return values.length > 0
      ? {
          key: category,
          label: category,
          content: <div className="values">{values}</div>,
        }
      : null;
  });

  return (
    <div className={editMode ? "drawer-section" : "hidden"}>
      {false && (
        <div className="content align-center">
          <LoadSpinner label="Loading User Permissions" />
        </div>
      )}

      <div className="policy">
        <div className="title">
          <p>Select the necessary actions to assign for</p>
          <p>
            Role Selected: <span className="role">{role}</span>
          </p>
        </div>

        <Dropdown
          id="roleselect"
          helperText="Role-based policies presest"
          onChange={(preset) =>
            dispatch({ type: "view", payload: preset === "View Only" })
          }
          reset={preset}
        >
          <Option key="all" value="All" />
          <Option key="viewonly" value="View Only" />
        </Dropdown>

        <Accordion
          items={items.filter(Boolean)}
          sx={{ marginTop: 20 }}
          expanders={expanders}
          onExpand={(curr) => dispatch({ type: "expanders", payload: curr })}
        />

        <div className="back">
          <Button outline onClick={() => handleBack(toggles)}>
            <div className="icon">
              <BackIcon size={18} />
              <span>Back to Roles</span>
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
};
