import { useMemo, useState } from "react";
import Dropdown, { Option } from "../Dropdown";
import { SubmitButton } from "./SubmitButton";

export function ComboFilter({ field, id, title, subtitle, values, onApply }) {
  const [selection, setSelection] = useState("");
  const options = useMemo(
    () =>
      Object.values(values || {}).map((val) => (
        <Option key={val.replace(" ", "_")} value={val} />
      )),
    [values]
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    onApply({ field, value: selection, operator: "contains" });
    setSelection("");
  };

  return (
    <form id={id} className="w-[300px] sdrop" onSubmit={handleSubmit}>
      <p className="font-semibold">{title}</p>
      <p>{subtitle}</p>
      <Dropdown
        value={selection}
        sourceItems={options}
        onChange={(sel) => setSelection(sel)}
      />
      <SubmitButton enabled={Boolean(selection)} />
    </form>
  );
}
