import { Fragment, useCallback, useState } from "react";
import { PiUsersDuotone as UsersIcon } from "react-icons/pi";
import { Button } from "../../components";
import { createRadioGroup } from "../../form/FormCreate";
import Restrict from "../../services/Restrict";
import { Urls } from "../../store/axios";
import { useAxiosPrivate, useForm, useReport } from "../../store/hooks";

export const UserReport = ({ dispatch }) => {
  const [loading, setLoading] = useState(false);
  const { client, xportControl } = useReport();
  const { renderForm } = useForm(userConfig);
  const { userradios } = renderForm();
  const axiosPrivate = useAxiosPrivate();

  const handleClick = useCallback(async () => {
    try {
      setLoading(true);
      const { data } = await client.fetchQuery({
        queryKey: ["user-rpts"],
        queryFn: async () =>
          await axiosPrivate.post(Urls.reports.users, {
            role: userradios.value,
          }),
      });

      if (data.length)
        xportControl.exportCSV(data, `${userradios.value}-users.csv`);
    } catch ({ response }) {
      const { detail } = response.data;
      dispatch(detail);
    } finally {
      setLoading(false);
    }

    // eslint-disable-next-line
  }, [client, userradios.value]);

  return (
    <div className="flex space-x-5">
      <span className="w-12 h-12 p-2 flex-ij rounded-full border border-slate-400 bg-neutral-50 drop-shadow-sm">
        <UsersIcon color="brown" size={36} />
      </span>
      <div className="grid grid-cols-1 content-between h-full">
        <div className="w-[96%]">
          <span className="font-semibold">Users List</span>
          <p className="mb-3 text-[14px] leading-tight">
            The Users List report contains all user related information such as
            names, statuses, roles and any user properties.
          </p>
        </div>
        <Restrict allowedAction="ExportUsersList">
          <div className="flex space-x-3">
            {Object.values(userradios.radios).map((control) => (
              <Fragment key={control.props?.id}>{control}</Fragment>
            ))}
          </div>
          <div>
            <Button
              content="Generate CSV Report"
              onClick={handleClick}
              loading={loading}
            />
          </div>
        </Restrict>
      </div>
    </div>
  );
};

const userConfig = {
  userradios: {
    type: "radio-group",
    required: true,
    ...createRadioGroup({
      name: "usersgrp",
      group: {
        allusers: {
          id: "allusers",
          value: "all",
          label: "All",
          selected: true,
        },
        adminsonly: {
          id: "adminsonly",
          value: "admins",
          label: "Admins Only",
        },
        usersonly: {
          id: "usersonly",
          value: "users",
          label: "Users Only",
        },
      },
    }),
  },
};
