import { Checkbox, Dropdown, InputField, Radio, Option } from "../components";

export function createCheck({ ...props }) {
  return {
    renderControl: (checked, onChange) => {
      return <Checkbox checked={checked} onChange={onChange} {...props} />;
    },
  };
}

export function createDropdown({ ...props }) {
  return {
    renderControl: (options, selection, onSelectionChange) => {
      const renderOptions = options.map((option) => (
        <Option key={option} selected={option === selection} value={option} />
      ));

      return (
        <Dropdown
          key={props.id}
          value={selection}
          sourceItems={renderOptions}
          onChange={(sel) => onSelectionChange(props.id, sel)}
          {...props}
        />
      );
    },
  };
}

export function createInput({ ...props }) {
  return {
    renderControl: (
      touched,
      value,
      defaultValue,
      error,
      onInputChange,
      onInputFocus,
      onInputBlur,
      required = false
    ) => {
      return (
        <InputField
          key={props.name}
          touched={touched}
          errorMessage={error}
          value={(value === undefined ? defaultValue : value) || ""}
          onChange={onInputChange}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
          required={required}
          {...props}
        />
      );
    },
    touched: false,
    // validateOnBlur: false,
    valid: false,
    error: undefined,
  };
}

export function createPasswordInput({ defaultValue, ...props }) {
  return createInput({ defaultValue, ...props });
}

export function createRadioGroup({ name, group }) {
  return {
    renderControl: (
      key,
      touched,
      selectedValue,
      defaultValue,
      radioGroupHandler
    ) => {
      let currValue = selectedValue;
      const radios = Object.values(group).reduce((radioObj, choice) => {
        const { value, selected, ...rest } = choice;
        // const checked = [selectedValue, defaultValue].includes(value);
        const checked = selectedValue
          ? selectedValue === value
          : value === defaultValue;

        if (checked && !currValue) currValue = value;

        // set the default value for the radio group
        // if there is a default selection
        radioObj[rest.id] = (
          <Radio
            key={rest.id}
            name={name}
            {...rest}
            checked={!!checked}
            onChange={() => radioGroupHandler(key, value)}
          />
        );

        return radioObj;
      }, {});

      return { radios, value: currValue };
    },
  };
}
