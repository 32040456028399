import { memo, useCallback } from "react";

const Radio = ({ label, ...props }) => {
  return (
    <div className="radio-container">
      <input type="radio" {...props} />
      <label htmlFor={props.id}>{label}</label>
    </div>
  );
};

export const RadioGroup = memo(
  ({ group, name, onRadioChange, selectedValue, disabled }) => {
    const handleChange = useCallback((value) => {
      if (onRadioChange) onRadioChange(name, value);
      // eslint-disable-next-line
    }, []);

    return Object.values(group).map((choice) => {
      return (
        <Radio
          name={name}
          id={choice.id || choice.value}
          key={choice.value}
          checked={choice.value === selectedValue}
          disabled={disabled}
          onChange={() => handleChange(choice.value)}
          {...choice}
        />
      );
    });
  }
);

export default Radio;
