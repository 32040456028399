import { useCallback, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useAxiosPrivate, useForm, useToast } from "../../store/hooks";
import { Button, Divider } from "../../components";
import { createDropdown, createInput } from "../../form/FormCreate";
import { required, ipCheck, isPort } from "../../form/validation-rules";

function getMessage(id) {
  return {
    instance:
      "The name of the Mirth Connect instance where all the Mirth Channels will be accessible.",
    ipaddress:
      "Local area network that contains broadcasts within itself and only hosts that belong to that VLAN will see those broadcasts.",
    hostname:
      "The URL which allows access to the Mirth Connect Server via a well-defined API.",
    port: "This port is used to communicate with the NextGen Connect Integration Engine Server.",
  }[id];
}

export const ServerForm = ({ server = {}, onClose, onInsert }) => {
  const { element: toastEl, warn, info } = useToast();
  const [isDisabled, setIsODisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const serverMutation = useMutation({
    mutationFn: async (newServer) => {
      setIsODisabled(true);
      return isUpdate
        ? axiosPrivate.put(`/mirth/servers/${server.serverId}`, newServer)
        : axiosPrivate.post(`/mirth/servers`, newServer);
    },
    onSuccess: async ({ data }) => {
      if (data.status === "error") warn(data.message);
      else {
        onInsert(data.data.server);
        onClose();
      }
    },
    onError: ({ response }) => {
      const error =
        response.status === 404
          ? "NewServer (mutation) resource not found."
          : response.data.detail;
      warn(error);
    },
    onSettled: () => setIsODisabled(false),
    retry: false,
  });

  const handleFocus = useCallback((target) => info(getMessage(target)), [info]);

  const serverConfig = useMemo(
    () => ({
      instance: {
        type: "input",
        ...createInput({
          type: "text",
          id: "instance",
          name: "newserver",
          width: 248,
          helperText: "Mirth Connect Instance",
        }),
        defaultValue: server.name,
        required: true,
        validateOnBlur: true,
        validationRules: [required("Server Name")],
        focusHandler: () => handleFocus("instance"),
      },
      ipaddress: {
        type: "input",
        ...createInput({
          id: "ipaddress",
          name: "newserver-ip",
          helperText: "VLAN or IP Address (e.g.: 127.0.0.1)",
          outline: true,
        }),
        defaultValue: server.ipaddress,
        required: true,
        validateOnBlur: true,
        validationRules: [required("IP Address"), ipCheck()],
        focusHandler: () => handleFocus("ipaddress"),
      },
      hostname: {
        type: "input",
        ...createInput({
          id: "hostname",
          width: 280,
          name: "newserver-url",
          helperText: "Hostname or API endpoint",
        }),
        defaultValue: server.hostname,
        required: true,
        validateOnBlur: true,
        validationRules: [required("Hostname")],
        focusHandler: () => handleFocus("hostname"),
      },
      port: {
        type: "input",
        ...createInput({
          id: "port",
          name: "newserver-port",
          helperText: "Administrator Port",
        }),
        defaultValue: server.port,
        required: true,
        validateOnBlur: true,
        validationRules: [required("Port Number"), isPort()],
        focusHandler: () => handleFocus("port"),
      },
      dilp: {
        type: "dropdown",
        ...createDropdown({
          id: "dilp",
          name: "newserver-zone",
          helperText: "Data Ingestion Landing Point",
        }),
        value: server.dilp,
        items: ["Atlanta Data Center", "AWS Cloud", "Other"],
      },
    }),
    [server, handleFocus]
  );

  const { renderForm, isFormValid } = useForm(serverConfig);
  const inputs = renderForm();

  const handleAddServer = async (event) => {
    event.preventDefault();
    const formValid = await isFormValid();

    if (formValid) {
      const newServerObj = Object.values(inputs).reduce(
        (valueObj, inputObj) => {
          valueObj[inputObj.props.id] = inputObj.props.value;
          return valueObj;
        },
        { id: server.serverId }
      );

      serverMutation.mutate(newServerObj);
    }
  };

  const isUpdate = !!server.serverId;
  const { instance, hostname, ipaddress, port, dilp } = inputs;

  return (
    <form id="server-form" onSubmit={handleAddServer}>
      <section className="drawer-section">
        <fieldset className="fs-server">
          <div className="flex3">
            <span>
              Server Name
              {instance}
            </span>
            <div className="mt-4">{ipaddress}</div>
          </div>
          <div className="flex3">
            <span>
              Client Hostname
              {hostname}
            </span>
            <span>
              Port
              {port}
            </span>
          </div>
          <div>
            <span>
              DILP (optional)
              {dilp}
            </span>
          </div>

          {toastEl}
        </fieldset>

        <div className="flex space-x-2 mb-1">
          <Button
            outline
            content="Cancel"
            sx={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10 }}
            onClick={() => onClose(false)}
          />
          <Button
            type="submit"
            content={`${isUpdate ? "Update" : "Add"} Server`}
            sx={{ paddingTop: 10 }}
            disabled={isDisabled}
            loading={serverMutation.isLoading}
          />
          {isUpdate && (
            <>
              <Divider />
              <Button light content="Disconnect Server" />
            </>
          )}
        </div>
      </section>
    </form>
  );
};
