import { useState } from "react";
import {
  MdLayers as ListIcon,
  MdGesture as LocationIcon,
} from "react-icons/md";
import { Panel, PanelHeader } from "../../components";
import LocationList from "./LocationList";

const initialHeader = { title: "Service Locations", subtitle: "Facilities" };

const Locations = () => {
  const [header, setHeader] = useState(initialHeader);

  return (
    <div className="w-full space-y-4">
      <Panel>
        <PanelHeader
          PanelIcon={header.subtitle === "Facilities" ? ListIcon : LocationIcon}
          title={header.title}
          subtitle={header.subtitle}
        />
      </Panel>
      <LocationList
        onLocationChange={(header) => setHeader(header || initialHeader)}
      />
    </div>
  );
};

export default Locations;
