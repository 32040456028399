import { useCallback, useState } from "react";
import { RadioGroup } from "../Radio";
import { SubmitButton } from "./SubmitButton";

const choices = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

const BooleanFilter = ({ id, selectedValue, onApply }) => {
  const [value, setValue] = useState(null);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      onApply(value);
      setValue(null);
    },
    [onApply, value]
  );

  return (
    <form id={id} className="w-[240px] sdrop" onSubmit={handleSubmit}>
      <p className="font-semibold">Filter by Status</p>
      <p>Find records with the given status</p>

      <section className="pt-2 pb-3">
        <RadioGroup
          name="filter-grp"
          group={choices}
          selectedValue={value || selectedValue}
          onRadioChange={(_, value) => setValue(value)}
        />
      </section>
      <SubmitButton enabled={!!value} />
    </form>
  );
};

export default BooleanFilter;
