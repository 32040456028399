import { GoGitMerge as ServiceIcon } from "react-icons/go";
import { Panel, PanelHeader } from "../../components";
import { ServiceCharts } from "./ServiceCharts";
import { ServiceList } from "./ServiceList";
import { useFilter } from "../../store/hooks";

const Interfaces = () => {
  const [dispatch, client] = useFilter("services");

  return (
    <div className="w-full space-y-4">
      <Panel>
        <PanelHeader
          PanelIcon={ServiceIcon}
          title="Mirth Interfaces"
          subtitle="Services"
        />
      </Panel>
      <ServiceCharts total={client.total} />
      <ServiceList client={client} dispatch={dispatch} />
    </div>
  );
};

export default Interfaces;
