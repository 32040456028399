import { useMemo } from "react";
import { BsCloudDownload as ServerIcon } from "react-icons/bs";
import {
  MdOutlineWorkspaces as ServiceIcon,
  MdOutlineSettings as SettingsIcon,
} from "react-icons/md";
import { SlRefresh as RefreshIcon } from "react-icons/sl";
import {
  BadgeIndicator,
  IconButton,
  Indicator,
  Ping,
  RepoTooltip,
} from "../../components";
import Restrict from "../../services/Restrict";
import { useAlert, useCommon, useModal } from "../../store/hooks";
import { RepositoryBuild } from "../home/RepositoryBuild";
import { ServerProgress } from "./ServerProgress";

/**
 * Indicator and import status for the Server Manager
 * green ~> the process is complete
 * blue ~> on track or processing
 * yellow ~> at risk
 * @returns the indicator component and
 */
function getIndicator(status) {
  return status === "A" ? (
    <BadgeIndicator color="ok" label="Active" />
  ) : status === "R" ? (
    <BadgeIndicator color="basic" label="Ready" />
  ) : status === "S" ? (
    <BadgeIndicator color="progress" label="Staging" />
  ) : (
    <Indicator color="danger" />
  );
}

function createButtons(config) {
  return config.reduce((buttonList, btn) => {
    if (btn.handler) {
      const linkButton = (
        <RepoTooltip
          key={`${btn.label}-btn`}
          label={btn.label}
          position="bottom"
        >
          <IconButton
            icon={btn.Icon && <btn.Icon size={24} />}
            onClick={btn.handler}
          />
        </RepoTooltip>
      );
      buttonList.push(
        btn.restrict ? (
          <Restrict
            key={`${btn.label}-restrict`}
            allowedAction={btn.restrict}
            hidden
          >
            {linkButton}
          </Restrict>
        ) : (
          linkButton
        )
      );
    }
    return buttonList;
  }, []);
}

export const ServerManager = ({ server, onSync, onUpdate }) => {
  const { loadCommon } = useCommon();
  const { repoAlert, setErrorMessage } = useAlert("server-alert");

  const { modal, showModal } = useModal(RepositoryBuild, {
    server,
    onClose: loadCommon,
  });

  const { staging } = server.dataImport || {};
  const { status, isService } = staging || {};

  const linkConfig = useMemo(
    () => [
      {
        label: "Mirth Server Sync",
        Icon: RefreshIcon,
        handler: () => onSync(server.serverId),
      },
      {
        label: "Build Repository",
        restrict: "BuildRepository",
        Icon: ServiceIcon,
        handler: showModal,
      },
      {
        label: "Settings",
        Icon: SettingsIcon,
        handler: () => onUpdate(server),
      },
    ],
    // eslint-disable-next-line
    [onSync, showModal]
  );

  let content =
    server.status === "S" && !isService ? (
      <ServerProgress
        serverId={server.serverId}
        staging={staging}
        onComplete={loadCommon}
        onAlert={setErrorMessage}
      />
    ) : (
      <div className="server-links">{createButtons(linkConfig)}</div>
    );

  return (
    <div className="smanager">
      {repoAlert}
      <div className="indicatr">
        {isService && status?.code === "W" ? (
          <Ping />
        ) : (
          getIndicator(server.status)
        )}
      </div>

      <div className="logo">
        <div className="icon">
          <ServerIcon size={36} color="#FF6F00" />
        </div>
      </div>

      <div className="server">
        <p className="t15-semi-tt">{server.name}</p>
        <p className="sm-text">
          {server.hostname}:{server.port}
        </p>
        <p className="text-sm">{server.dilp}</p>
      </div>

      <div className="details">
        <div>
          <p>{server.ipaddress}</p>
          <span>ip address</span>
        </div>
        <div>
          <p>{server.channels.toLocaleString()}</p>
          <span>channels</span>
        </div>
        <div>
          <p>{server.status === "R" ? "---" : server.lastSynced}</p>
          <span>last synced</span>
        </div>
      </div>
      {content}
      {modal}
    </div>
  );
};
