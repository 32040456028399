import { PiUsersDuotone as UserIcon } from "react-icons/pi";
import "../../assets/css/users.main.css";
import { Panel, PanelHeader, TabContent, Tabs } from "../../components";
import UserList from "./UserList";

const Users = () => {
  return (
    <>
      <Panel>
        <PanelHeader
          PanelIcon={UserIcon}
          title="User Management"
          subtitle="Accounts"
        />
      </Panel>
      <Tabs>
        <TabContent label="Users">
          <UserList />
        </TabContent>
      </Tabs>
    </>
  );
};

export default Users;
