import { memo } from "react";
import hcaLogo from "../../assets/images/hca.png";
import { Card, Divider, StackedAvatar } from "../../components";

export const SummaryHeader = memo(({ isHCA, org, locations }) => {
  const specialties = Array.from(
    locations.reduce((sObj, l) => {
      for (let s of l.specialty) sObj.add(s.code);
      return sObj;
    }, new Set())
  );

  return (
    <Card sx={{ padding: 20 }}>
      <div className="flex items-center justify-start ml-16 space-x-5">
        {org.type === "team" ||
          (isHCA && (
            <>
              <img src={hcaLogo} width={100} alt="HCA Logo" />
              <Divider />
            </>
          ))}
        <div className="leading-tight flex flex-col w-auto min-w-min">
          <span className="text-lg">{org.name}</span>
          <span className="-mt-1 text-sm text-[#E55A27] font-semibold">
            {locations.length} location(s), {specialties.length} service(s)
          </span>
        </div>
        <StackedAvatar labels={specialties || []} />
      </div>
    </Card>
  );
});
