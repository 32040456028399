import { useMutation } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import { BsCheck2Circle as VerifiedIcon } from "react-icons/bs";
import { Button, InputField } from "../../../components";
import {
  isValidEmail,
  required,
  validationHandler,
} from "../../../form/validation-rules";
import { Urls } from "../../../store/axios";
import { useDebounce, useUserRequest } from "../../../store/hooks";

const emailRules = [required("Email"), isValidEmail("Account")];

export const UserCreate = ({ onSetEmail }) => {
  const [account, setAccount] = useState({});
  const { apiClient } = useUserRequest();

  const handleBlur = useCallback((email) => {
    setAccount({ valid: false });
    const { valid, error } = validationHandler(emailRules, email);
    setAccount({ email, error, valid });
  }, []);

  const [setInputValue] = useDebounce(
    500,
    null,
    useCallback((debounce) => handleBlur(debounce), [handleBlur])
  );

  const verifyMutation = useMutation({
    mutationFn: (email) => apiClient.post(Urls.verify, { email }),
    onSuccess: ({ data }) => {
      if (data.valid) {
        onSetEmail(data.email);
        setAccount((curr) => ({ ...curr, verified: true }));
      } else
        setAccount((curr) => ({
          ...curr,
          error: "An account with this email already exists",
        }));
    },
    onError: ({ response }) => {
      const { detail } = response.data;
      setAccount((curr) => ({ ...curr, verified: false, error: detail }));
    },
  });

  const handleEmailChange = useCallback(
    ({ target }) => {
      setInputValue(target.value);
    },
    [setInputValue]
  );

  const handleVerifyFormSubmit = async (event) => {
    event.preventDefault();

    if (account.valid && !account.message) verifyMutation.mutate(account.email);
  };

  return (
    <div className="flexj">
      <form
        onSubmit={handleVerifyFormSubmit}
        className="flex my-4 ml-2 space-x-4"
      >
        <InputField
          required
          id="username"
          label="Email Address"
          helperText="Envision Healthcare email address"
          width={340}
          errorMessage={account.error}
          onChange={handleEmailChange}
          onBlur={({ target }) => handleBlur(target.value)}
        />

        <div className="mt-1">
          {account.verified ? (
            <VerifiedIcon className="text-[28px] text-green-600 mb-4" />
          ) : (
            <Button
              sx={{ width: 100 }}
              type="submit"
              disabled={!account.valid}
              loading={verifyMutation.isLoading}
            >
              <div className="stepbtn">
                <span className="label">Verify Now</span>
              </div>
            </Button>
          )}
        </div>
      </form>
    </div>
  );
};
