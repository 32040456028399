import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import useAuth from "./use-auth";
import useAxiosPrivate from "./use-axios";

const useRefetch = (key) => {
  const [isFetching, setIsFetching] = useState(false);
  const { setAuth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const queryClient = useQueryClient();

  const fetchQuery = useCallback(async (method, url, params) => {
    try {
      setIsFetching(true);
      const { data } = await queryClient.fetchQuery({
        queryKey: [key],
        queryFn: () =>
          method === "GET"
            ? axiosPrivate(url)
            : axiosPrivate.post(url, params || {}),
      });

      return { data };
    } catch (error) {
      if (error.response) {
        const { status, data } = error.response;
        if (status === 401)
          setAuth((prev) => ({ ...prev, isRefresToken: true }));
        return { data: null, error: data.detail };
      } else return { data: null, error: "SQL error while retrieving records" };
    } finally {
      setIsFetching(false);
    }
    // eslint-disable-next-line
  }, []);

  return { fetchQuery, isFetching };
};

export default useRefetch;
