import { useCallback, useEffect, useState } from "react";
import { MdDone } from "react-icons/md";
import { Button, Checkbox, IconButton } from "../../components";

export const ReportConnectorForm = ({ connectors, onAccept, onClose }) => {
  const [controls, setControls] = useState([]);
  const [selections, setSelections] = useState({});

  const handleChange = useCallback((connector, checked) => {
    setSelections((curr) => ({ ...curr, [connector]: checked }));
  }, []);

  const handleAccept = useCallback(() => {
    onAccept(selections);
  }, [selections, onAccept]);

  const handleClose = useCallback(() => {
    onClose();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let [controls, selections] = [[], {}];
    for (let [control, checked] of Object.entries(connectors)) {
      selections[control] = checked;
      controls.push(
        <Checkbox
          key={control}
          id={`${control}-chk`}
          label={control}
          defaultChecked={checked}
          onChange={({ target }) => handleChange(control, target.checked)}
        />
      );
    }

    setControls(controls);
    setSelections(selections);
  }, [connectors, handleChange]);

  return (
    <section>
      <div className="py-2 px-4 text-sm bg-white">
        <div className="font-semibold text-gray-700 py-1 pb-2 tracking-tight">
          Source Connectors
        </div>
        {controls}
      </div>
      <div className="flex items-center justify-end p-3 space-x-3 border-t border-gray-200 rounded-b-lg bg-gray-50 hover:bg-gray-100">
        <IconButton id="close-btn" label="Close" onClick={handleClose} />
        <Button id="apply-btn" onClick={handleAccept}>
          <div className="flex-ij text-xs">
            <MdDone size={18} />
            <span>Accept</span>
          </div>
        </Button>
      </div>
    </section>
  );
};
