import { forwardRef, useCallback, useReducer } from "react";
import { RepoTooltip, SimpleButton } from "../";
import { MdCheck as UpdateIcon, MdClose as CancelIcon } from "react-icons/md";

const expr = /^(\d{9,16})$/i;

const reducer = (state, action) => {
  switch (action.type) {
    case "edit":
      const number = action.payload;
      return {
        ...state,
        editing: true,
        number,
        valid: number ? expr.test(number) : true,
      };
    case "update":
      return { ...state, editing: false };
    case "cancel":
      return { ...state, editing: false, number: action.payload, valid: true };
    default:
      return state;
  }
};

const FloatingInput = forwardRef(
  (
    {
      label,
      helperText,
      defaultValue,
      onChange,
      onPopOver,
      onCancel,
      ...props
    },
    ref
  ) => {
    const [phone, dispatch] = useReducer(reducer, {
      number: defaultValue,
      editing: false,
      valid: null,
    });

    const handleCancelUpdate = useCallback(() => {
      dispatch({ type: "cancel", payload: defaultValue });
      onCancel();
    }, [defaultValue, onCancel]);

    const handleUpdate = useCallback(() => {
      if (phone.valid) {
        onPopOver(phone.number, defaultValue === phone.number);
        dispatch({ type: "update" });
      }
    }, [onPopOver, defaultValue, phone]);

    return (
      <div className="float-input">
        <div className="input-container" style={{ width: props?.width }}>
          <input
            className={phone.valid === null || phone.valid ? "" : "error"}
            ref={ref}
            type="text"
            value={phone.number}
            onChange={({ target }) => {
              if (onChange) onChange();
              dispatch({ type: "edit", payload: target.value });
            }}
            {...props}
          />
          <label htmlFor={props.id}>{label}</label>
          {helperText && <div className="helper">{helperText}</div>}
        </div>

        <div
          data-popover
          id="popover-right"
          role="tooltip"
          className={`popover ${
            phone.editing ? "visible opacity-100" : "invisible opacity-0"
          }`}
        >
          <RepoTooltip id="accept-tip" label="Accept" position="bottom">
            <SimpleButton
              className="pop-btn"
              content={<UpdateIcon size={18} />}
              onClick={handleUpdate}
              disabled={!phone.valid && phone.editing}
            />
          </RepoTooltip>
          <RepoTooltip id="cancel-tip" label="Cancel" position="right">
            <SimpleButton
              className="pop-btn"
              content={<CancelIcon size={18} />}
              onClick={handleCancelUpdate}
            />
          </RepoTooltip>
          <div data-popper-arrow></div>
        </div>
      </div>
    );
  }
);

export default FloatingInput;
