import { memo, useCallback, useState } from "react";
import {
  MdClose as CloseIcon,
  MdNavigateNext as NextIcon,
} from "react-icons/md";
import { Button, Divider, InputField } from "../../../components";
import { useForm, usePasswordGen, useUserRequest } from "../../../store/hooks";
import { UserCreate, createConfig } from ".";

export const AccountCreate = memo(({ onClose }) => {
  const [userEmail, setUserEmail] = useState(null);
  const [fullname, setFullname] = useState("");
  const { userRequest, onNext, createUser } = useUserRequest();
  const { password } = usePasswordGen(10);

  const handleFullname = useCallback((control, value) => {
    setFullname((curr) => {
      let names = curr ? curr.split(" ") : ["", ""];
      let index = control === "given" ? 0 : names.length - 1;
      names[index] = value;
      return names.join(" ");
    });
  }, []);

  const accountConfig = createConfig(handleFullname);

  const { renderForm, isFormValid } = useForm(accountConfig);
  const controls = renderForm();

  const currClass = userEmail ? "trans-opac" : "opacity-none";

  const setEmailHandler = useCallback(
    (email) => {
      if (email) {
        setUserEmail(email);
        createUser(email);
      }
    },
    [createUser]
  );

  const handleClose = useCallback(() => {
    setEmailHandler();
    onClose();
  }, [setEmailHandler, onClose]);

  const handleAccountFormSubmit = async (e) => {
    e.preventDefault();

    const formValid = await isFormValid();
    if (!(formValid && userEmail)) return;

    const payload = {
      ...Object.entries(controls).reduce((reduceObj, [key, control]) => {
        reduceObj[key] = control.props?.value;
        return reduceObj;
      }, {}),
    };

    userRequest.setName(payload.given, payload.family, fullname);
    userRequest.addPhone(payload.phone);
    userRequest.setDepartment(payload.usertitle, payload.department);
    userRequest.setCredentials(
      userEmail.replace("@envisionhealth.com", ""),
      password
    );
    userRequest.revokePassword();

    onNext();
  };

  return (
    <section className="drawer-section">
      <UserCreate onSetEmail={(email) => setEmailHandler(email)} />

      <form id="account-form" onSubmit={handleAccountFormSubmit}>
        <div className={`accnt ${currClass}`}>
          <p className="text-center mt-1 mb-3">
            Enter the basic information for the user.
          </p>

          <div className="controls">
            <div className="name">
              {controls.given}
              {controls.family}
            </div>
            <InputField
              id="newuser-display"
              name="newuser-display"
              label="Display Name"
              value={fullname}
              onChange={({ target }) => setFullname(target.value)}
            />
            {controls.phone}
            {controls.usertitle}
            {controls.department}
          </div>
        </div>

        <div className={`flexj ${currClass} space-x-2 pt-5 mb-5`}>
          <Button onClick={handleClose}>
            <div className="stepbtn">
              <span>
                <CloseIcon size={18} />
              </span>
              <span className="label pr-1">CANCEL</span>
            </div>
          </Button>
          <Divider />
          <Button type="submit" disabled={userEmail === null}>
            <div className="stepbtn">
              <span className="label pl-1.5">NEXT</span>
              <span>
                <NextIcon size={20} />
              </span>
            </div>
          </Button>
        </div>
      </form>
    </section>
  );
});
