import { memo } from "react";
import { Card, Panel, PanelBody, PanelHeader } from "../../components";
import { useCommon } from "../../store/hooks";
import {
  Area,
  CartesianGrid,
  ResponsiveContainer,
  YAxis,
  Tooltip,
  XAxis,
  AreaChart,
} from "recharts";

function arrSwitch(list, i, j) {
  const tmp = list[i];
  list[i] = list[j];
  list[j] = tmp;
}

const ChannelsChart = () => {
  const { serverArr, sources, destinations } = useCommon();

  if (destinations === undefined) return;

  // merge stats into series of protocols
  const seriesObj = destinations.reduce((reduceObj, { connector, active }) => {
    reduceObj[connector] = {
      protocol: connector,
      Writers: active || 0,
      Readers: 0,
    };
    return reduceObj;
  }, {});

  for (let source of sources) {
    if (source.connector in seriesObj)
      seriesObj[source.connector].Readers = source.active;
    else
      seriesObj[source.connector] = {
        protocol: source.connector,
        Readers: source.active || 0,
        Writers: 0,
      };
  }

  let channelsCount = 0;
  const serversList = serverArr.map((s) => {
    channelsCount += s.count;
    return (
      <li key={s.serverId} className="border-b border-neutral-200">
        <div className="flexib tracking-tight">
          <span>{s.server}</span>
          <span>{s.count.toLocaleString()}</span>
        </div>
      </li>
    );
  });

  let chart = <div className="h-[208px]"></div>;
  const series = Object.values(seriesObj);

  if (series.length) {
    chart = (
      <ResponsiveContainer width="100%" height={296}>
        <AreaChart data={series}>
          <defs>
            <linearGradient id="colorReaders" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>

            <linearGradient id="colorWriters" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis
            dataKey="protocol"
            tick={{ fontSize: 13, transform: "translate(-1, 4)" }}
          />
          <YAxis tick={{ fontSize: 14 }} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip itemStyle={{ fontSize: 13 }} />
          <Area
            type="monotone"
            dataKey="Readers"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#colorReaders)"
          />
          <Area
            type="monotone"
            dataKey="Writers"
            stroke="#82ca9d"
            fillOpacity={1}
            fill="url(#colorWriters)"
          />
        </AreaChart>
      </ResponsiveContainer>
    );

    // temp positional hack
    arrSwitch(series, 2, series.length - 1);
  }

  return (
    <div className="flex3 w-full">
      <div className="stats-channels">
        <Panel>
          <PanelHeader>
            <p className="title -m-4">Channels</p>
          </PanelHeader>
          <PanelBody>
            <div className="stat-container">
              <span>{channelsCount.toLocaleString()}</span>
              <p>Source Connectors</p>
            </div>
          </PanelBody>
        </Panel>
        <Card sx={{ height: "100%", padding: 16 }}>
          <ol className="w-full h-[102px] pt-2 space-y-2">{serversList}</ol>
        </Card>
      </div>
      <Card sx={{ width: "75%" }}>
        <main className="-ml-7 pt-2">{chart}</main>
      </Card>
    </div>
  );
};

export default memo(ChannelsChart);
