import { useCallback, useEffect, useMemo, useState } from "react";
import { Button } from "../../components";
import { useForm } from "../../store/hooks";
import { createInput } from "../../form/FormCreate";
import { required } from "../../form/validation-rules";
import { TbCloudDownload as DownloadIcon } from "react-icons/tb";

export const MirthLoginForm = ({ serverName, title, loading, onAuthorize }) => {
  const [enabled, setEnabled] = useState(false);

  const downloadConfig = useMemo(
    () => ({
      apiorigin: {
        type: "input",
        ...createInput({
          id: "apiorigin",
          type: "text",
          width: 252,
          helperText: "Content Server Origin",
          disabled: true,
        }),
        defaultValue: serverName,
        required: true,
        valid: true,
      },
      apiuser: {
        type: "input",
        ...createInput({
          id: "apiuser",
          type: "text",
          width: 252,
          placeholder: "Mirth Username",
        }),
        defaultValue: process.env.REACT_APP_MIRTH_USER,
        required: true,
        valid: true,
        validateOnBlur: true,
        validationRules: [
          required("Mirth Username"),
          required("Mirth Password"),
        ],
      },
      apipwd: {
        type: "input",
        ...createInput({
          id: "apipwd",
          type: "password",
          width: 252,
          placeholder: "Mirth Password",
        }),
        defaultValue: process.env.REACT_APP_MIRTH_PASSWORD,
        required: true,
        valid: true,
        validateOnBlur: true,
        validationRules: [required("Mirth Password")],
      },
    }),
    [serverName]
  );

  const { renderForm, isFormValid } = useForm(downloadConfig);
  const { apiorigin, apiuser, apipwd } = renderForm();

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const formValid = isFormValid();
      if (!formValid) return;

      onAuthorize(apiuser.props.value, apipwd.props.value);
    },
    // eslint-disable-next-line
    [onAuthorize, apiuser, apipwd]
  );

  useEffect(() => {
    setEnabled(apiuser.props.value && apipwd.props.value);
  }, [apiuser, apipwd]);

  return (
    <form onSubmit={handleSubmit} className="flex-ij-col w-full space-y-2">
      <span className="text-primary flex-j m-2 p-2">
        <DownloadIcon size={36} />
      </span>
      {apiorigin}
      {apiuser}
      {apipwd}
      <Button
        type="submit"
        content={title}
        disabled={loading || !enabled}
        sx={{ width: 252 }}
      />
    </form>
  );
};
