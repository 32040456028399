import { Panel, PanelHeader } from "../../components";
import ChannelList from "./ChannelList";
import ChannelsChart from "./ChannelsChart";
import { SiMoleculer as ChannelsIcon } from "react-icons/si";

const Channels = () => {
  return (
    <div className="flex flex-col space-y-3">
      <Panel>
        <PanelHeader
          PanelIcon={ChannelsIcon}
          title="Mirth Channels"
          subtitle="Connectors"
        />
      </Panel>
      <ChannelsChart />
      <ChannelList />
    </div>
  );
};

export default Channels;
