import { createContext, useCallback, useMemo, useState } from "react";
import { useAxiosPrivate } from "../hooks";
import { Urls } from "../axios";

const initialCommon = {
  servers: { stats: [] },
  connectors: { sources: [], destinations: [] },
  notifications: [],
};

const initialBoard = {
  hospitals: {},
  locationFeeds: {},
  feeds: [],
  exchangeTypes: [],
  serverArr: [],
};

const CommonContext = createContext(initialCommon);

function getConnectors(payload) {
  const types = [];
  for (let [, values] of Object.entries(payload)) {
    for (let [connector, value] of Object.entries(values)) {
      types.push({
        connector,
        alias: value.name,
        active: value.active,
        inactive: value.inactive,
      });
    }
  }

  types.sort((a, b) => (a.active > b.active ? 1 : -1));
  return types;
}

export const CommonProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [{ sources, destinations }, setConnectors] = useState({});
  const [
    { hospitals, feeds, exchangeTypes, locationFeeds, serverArr },
    setDashboard,
  ] = useState(initialBoard);

  const axiosPrivate = useAxiosPrivate();

  const initialized = useMemo(() => sources?.length > 0, [sources]);

  const feedCount = useMemo(() => {
    return (exchangeTypes || []).reduce((sum, e) => {
      sum += e.value;
      return sum;
    }, 0);
  }, [exchangeTypes]);

  const serviceCount = useMemo(
    () => feeds.reduce((sum, f) => sum + f.total, 0),
    [feeds]
  );

  const fetchNotifications = useCallback(async () => {
    try {
      const { data } = await axiosPrivate(Urls.notifications);
      setNotifications(data);
    } catch ({ code, response }) {
      console.error(response);
    }
    // eslint-disable-next-line
  }, []);

  const fetchConnectorsStats = useCallback(
    async () => {
      const { data } = await axiosPrivate(Urls.stats);

      // update connectors only if sources and/or destinations
      // have not already been fulfilled
      if (initialized) return;

      setConnectors({
        sources: getConnectors(data.sources),
        destinations: getConnectors(data.dests),
      });
    },
    // eslint-disable-next-line
    [initialized]
  );

  const fetchDashboard = useCallback(async () => {
    const { data } = await axiosPrivate(Urls.dashboard);
    setDashboard({
      hospitals: data.hospital || {},
      exchangeTypes: data.exchange || [],
      feeds: data.serviceline || [],
      locationFeeds: data.locationFeeds || {},
      serverArr: data.servers || [],
    });
    // eslint-disable-next-line
  }, []);

  const loadCommon = async () =>
    await Promise.all([
      fetchDashboard(),
      fetchConnectorsStats(),
      fetchNotifications(),
    ]);

  const clearCommon = useCallback(() => {
    setNotifications([]);
    setConnectors({ sources: [], destinations: [] });
    setDashboard(initialBoard);
  }, []);

  const removeNotification = (noteId) => {
    setNotifications((curr) => {
      return noteId === "all" ? [] : curr.filter((n) => n.id !== noteId);
    });
  };

  const hasNotifications = useMemo(
    () => notifications?.length > 0,
    [notifications]
  );

  return (
    <CommonContext.Provider
      value={{
        initialized,
        notifications,
        feeds,
        locationFeeds,
        feedCount,
        serviceCount,
        serverArr,
        hospitals,
        sources,
        exchangeTypes,
        destinations,
        hasNotifications,
        clearCommon,
        loadCommon,
        removeNotification,
      }}
    >
      {children}
    </CommonContext.Provider>
  );
};

export default CommonContext;
