import { useCallback, useEffect, useState } from "react";
import { Checkbox } from "..";
import { SubmitButton } from "./SubmitButton";

const CheckFilter = ({ id, title, values, filter, dispatch, onApply }) => {
  const [enabled, setEnabled] = useState(null);
  const [checks, setChecks] = useState({});
  const [controls, setControls] = useState({});

  const build = useCallback(
    (checks, values) =>
      values.map((source, i) => (
        <Checkbox
          key={i}
          id={`check-${i}`}
          label={source}
          onChange={() =>
            setChecks((prev) => ({ ...prev, [source]: !prev[source] }))
          }
          checked={checks[source] || false}
        />
      )),
    []
  );

  useEffect(() => {
    setControls({ sources: build(checks, values) });
    setEnabled(true);
  }, [checks, values, build]);

  useEffect(() => {
    if (Object.keys(filter).length === 0 && Object.keys(checks).length > 0)
      setChecks({ filter: false });
    // eslint-disable-next-line
  }, [filter]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setEnabled(false);
    onApply(checks);
  };

  return (
    <form id={id} className="min-w-[220px] sdrop" onSubmit={handleSubmit}>
      <div>
        <p className="font-semibold">{title}</p>
        <div className="mt-2">{controls.sources}</div>
      </div>

      <SubmitButton enabled={enabled} />
    </form>
  );
};

export default CheckFilter;
