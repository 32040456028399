import { useCallback } from "react";
import { Switcher, SwitchRoute } from "../../components";
import { useUserRequest } from "../../store/hooks";
import {
  ResponseCreate,
  AccountCreate,
  RoleCreate,
  UserReview,
} from "./interactive";

const NewUserForm = ({ onClose }) => {
  const { stepIndex } = useUserRequest();

  const handleClose = useCallback(
    (success = false) => onClose(success),
    [onClose]
  );

  return (
    <Switcher activeIndex={stepIndex}>
      <SwitchRoute element={<AccountCreate onClose={handleClose} />} />
      <SwitchRoute element={<RoleCreate />} />
      <SwitchRoute element={<UserReview />} />
      <SwitchRoute
        element={
          <ResponseCreate onClose={(isSuccess) => handleClose(isSuccess)} />
        }
      />
    </Switcher>
  );
};

export default NewUserForm;
