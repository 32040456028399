import { memo, useCallback, useEffect, useState } from "react";
import { Dropdown, Option, Table } from "../../components";
import FlowRender from "../../services/FlowRender";
import {
  useOnce,
  useRefetch,
  useSortableData,
  layoutTree,
} from "../../store/hooks";
import { facilityNodes } from "./elements";

function createRow({ feeds }) {
  const arr = [],
    serviceArr = new Set();

  Object.entries(feeds).forEach(([org, sources]) => {
    Object.entries(sources).forEach(([source, feed]) => {
      let { subservices, ...rest } = feed;
      const lf = rest;
      lf.source = source;
      lf.organization = org;
      arr.push(lf);
      subservices.forEach((s) => {
        if (s.subservice) serviceArr.add(s.subservice);
      });
    });
  });

  return [arr, Array.from(serviceArr)];
}

function FlowControls({ selection, values, onServiceChange }) {
  const options = values.map((sl) => (
    <Option key={sl.replace(" ", "_")} value={sl} />
  ));

  return (
    <div className="absolute right-6 top-2 z-10 text-sm min-w-20">
      <Dropdown
        clearButton
        outline
        helperText="Service Lines"
        width={190}
        value={selection}
        sourceItems={options}
        onChange={(sel) => onServiceChange(sel)}
      />
    </div>
  );
}

export const LocationDetails = memo(({ id }) => {
  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [controls, setControls] = useState(null);
  const [selection, setSelection] = useState(null);
  const [{ feeds, data }, setData] = useState({});
  const { fetchQuery } = useRefetch(["locations"], { id });

  const handleLayout = useCallback((data, selection) => {
    const [nodes, edges] = facilityNodes(data, selection);
    const layoutedNodes = layoutTree(nodes, edges);
    setNodes(layoutedNodes);
    setEdges(edges);
    // eslint-disable-next-line
  }, []);

  useOnce(() => {
    fetchQuery("POST", `/repo/locations/${id}`).then(({ data }) => {
      const [feeds, services] = createRow(data || {});
      setData({ feeds, data });
      setControls(
        <FlowControls
          selection={selection}
          values={services || []}
          onServiceChange={(sel) => setSelection(sel)}
        />
      );
    });
  });

  useEffect(() => {
    if (data) handleLayout(data, selection);
  }, [selection, data, handleLayout]);

  const { dataSorted, headerConfig } = useSortableData(feeds, ldConfig);

  return (
    <main className="w-full space-y-4">
      <Table loose dataSource={dataSorted} config={headerConfig} />
      <div className="feed-flow min-h-[640px]">
        <FlowRender
          initialNodes={nodes}
          initialEdges={edges}
          fitView={false}
          showControls={false}
          zoom={1.25}
        >
          {controls}
        </FlowRender>
      </div>
    </main>
  );
});

const ldConfig = [
  {
    key: "lf-row",
    render: () => null,
  },
  {
    label: "Source Feed",
    render: (l) => l.source,
    sortValue: (l) => l.source,
  },
  {
    label: "Group",
    render: (l) => l.organization,
    sortValue: (l) => l.organization,
  },
  {
    label: (
      <div className="grid grid-cols-3 gap-x-1">
        <p>eCode</p>
        <p>COCID</p>
        <p>Connect Id</p>
      </div>
    ),
    render: (l) => (
      <div className="grid grid-cols-3 gap-x-1">
        <p>{l.ecode || "--"}</p>
        <p>{l.cocid}</p>
        <p>{l.connectId || "--"}</p>
      </div>
    ),
  },
  {
    label: "Connector",
    render: (l) => l.connector,
  },
  {
    label: "Exchange Type",
    render: (l) => l.exchangeType,
    sortValue: (l) => l.exchangeType,
  },
];
