import { useCallback, useMemo, useState } from "react";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { ImFeed as FeedIcon } from "react-icons/im";
import { TbExchange as ExchangeIcon } from "react-icons/tb";
import { IconButton, Indicator } from "../../components";
import QueryFilter, { FilterPanel } from "../../components/filters/QueryFilter";
import { useCommon, useTable } from "../../store/hooks";
import FeedSummary from "./FeedSummary";

export const FeedList = ({ dispatch, client }) => {
  const [serviceId, setServiceId] = useState(null);
  const { exchangeTypes } = useCommon();

  const handleClose = useCallback(
    (serviceId = null) => setServiceId(serviceId),
    []
  );

  const exOptions = useMemo(
    () => ({
      field: "exchange",
      id: "filter-ex-form",
      title: "Filter by exchange type",
      subtitle: "Find a facility that has a specific data exchange type.",
      values: Object.values((exchangeTypes || {}).map(({ type }) => type)),
    }),
    [exchangeTypes]
  );

  const feedConfig = useMemo(() => {
    return [
      {
        key: "code",
        render: (entry) => (
          <div className="hl7-avatar">
            <div className="inline-flex my-4 w-11 h-9 items-center justify-center border shadow rounded-sm border-slate-300 bg-slate-200">
              <span className="text-[12px] text-slate-500 font-semibold leading-none">
                {entry.hl7}
              </span>
            </div>
          </div>
        ),
      },
      {
        label: "Source Channel Feed",
        render: (entry) => (
          <div className="py-1 leading-tight">
            <p>{entry.source}</p>
            <span className="text-sm text-slate-500">{entry.uuid}</span>
          </div>
        ),
        sortValue: (entry) => entry.source,
      },
      {
        label: "Exchange Type",
        render: (entry) => (
          <div className="py-1 leading-tight">
            <p>{entry.exchange}</p>
            <span className="text-sm text-slate-500">
              {entry.dataformat} {entry.direction || "Inbound"}
            </span>
          </div>
        ),
      },
      {
        label: "Server Origin",
        render: (entry) => (
          <div className="py-1 leading-tight">
            <p>{entry.server}</p>
            <div className="text-sm text-slate-500 whitespace-nowrap">
              {entry.port && `Port ${entry.port}`}
            </div>
          </div>
        ),
        sortValue: (entry) => entry.server,
      },
      {
        label: "Status",
        render: (entry) => (
          <div className="flex-ij">
            <Indicator color={entry.active && "ok"} />
          </div>
        ),
        align: "text-center",
      },
      {
        key: "serviceView",
        render: (entry) => (
          <IconButton
            title="View Feed Summary"
            icon={<Icon size={18} />}
            sx={{ paddingRight: 10 }}
            onClick={() => handleClose(entry.id)}
          />
        ),
      },
    ];
  }, [handleClose]);

  const { table } = useTable(client, feedConfig, dispatch);

  return (
    <div className="w-full">
      {serviceId ? (
        <FeedSummary serviceId={serviceId} onClose={handleClose} />
      ) : (
        <>
          <FilterPanel>
            <QueryFilter
              id="serv-search"
              label="channel feed"
              dispatch={dispatch}
            />
            <QueryFilter
              type="select"
              id="ex-filter"
              label="data exchange"
              icon={ExchangeIcon}
              options={exOptions}
              dispatch={dispatch}
            />
            <QueryFilter
              id="status-form"
              type="bool"
              label="Status"
              icon={FeedIcon}
              dispatch={dispatch}
            />
          </FilterPanel>
          {table}
        </>
      )}
    </div>
  );
};
