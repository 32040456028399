import { useMutation } from "@tanstack/react-query";
import { useState } from "react";
import { Button } from "../../components";
import { useAxiosPrivate, useCallWithRetry } from "../../store/hooks";

function ServerNotify({ label }) {
  return (
    <div className="server-progress">
      <span>This may take a few minutes.</span>
      <p>{label}</p>
    </div>
  );
}

export const ServerProgress = ({ serverId, staging, onComplete, onAlert }) => {
  const [loading, setLoading] = useState(false);
  const [isLaunched, setIsLaunched] = useState(false);
  const axiosPrivate = useAxiosPrivate();

  const stagingUrl = `/mirth/servers/${serverId}/staging/${staging?.id}`;

  const { withRetry } = useCallWithRetry({
    predicate: () =>
      new Promise(async (resolve, reject) => {
        try {
          const { data } = await axiosPrivate(stagingUrl);
          if (data.status.code === "S") return resolve(true);
          else if (data.status.code === "W") return resolve(false);
          else return reject(data.detail);
        } catch (error) {
          return reject(error);
        }
      }),
    onComplete,
    onSettled: () => setLoading(false),
  });

  const importMutation = useMutation({
    enabled: false,
    mutationKey: ["staging"],
    mutationFn: async () => axiosPrivate.post(stagingUrl, {}),
    onSuccess: ({ data }) => {
      const { data: stepfn, message, status } = data || {};
      if (stepfn?.invoked) {
        setIsLaunched(true);
        withRetry();
      } else {
        if (status === "success") onComplete();
        else onAlert(message);
      }
    },
    onError: ({ response }) => onAlert(response?.data?.detail),
    onSettled: () => setLoading(false),
  });

  return (
    <div className="flex mx-3 px-3">
      {staging &&
        (staging.isService ? (
          <ServerNotify label="Updating Repository..." />
        ) : (
          <>
            <ServerNotify label="Importing Mirth Channels" />
            <div className="flex-ij">
              {isLaunched ? (
                <Button
                  content={loading ? "Checking..." : "Check Status!"}
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    withRetry();
                  }}
                />
              ) : (
                <Button
                  content="Launch!"
                  loading={loading}
                  onClick={() => {
                    setLoading(true);
                    importMutation.mutate();
                  }}
                />
              )}
            </div>
          </>
        ))}
    </div>
  );
};
