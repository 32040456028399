import { memo } from "react";
import { MdClose as CloseIcon } from "react-icons/md";
import { Editor, EditorProvider } from "react-simple-wysiwyg";
import { Button, Card, Image, RepoTooltip, Table } from "../../components";
import { useSortableData } from "../../store/hooks";

const pdfContentType = "application/pdf";
const imageTypes = {
  "/": "jpeg",
  i: "png",
  R: "gif",
  U: "Webp",
};

function sanitizeHTML(data) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: data }}
      className="h-[99%] overflow-y-scroll my-2 p-5"
      id="repo-scroll"
    />
  );
}

/**
 * Create a new image holder.
 * By bas64 image content, if the first character is:
 *  /: jpg
 *  i: png
 *  R: gif
 *  U: webp
 *
 * @param {*} data
 * @returns
 */
function createImage(data) {
  return (
    <div id="repo-scrool" className="w-full h-full overflow-y-scroll">
      <Image
        src={`data:image/${imageTypes[data[0]]};base64,${data}`}
        alt="MR Imaging"
      />
    </div>
  );
}

function createPDFViewer(data) {
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) out[length] = bytes.charCodeAt(length);

  const blob = new Blob([out], { type: pdfContentType });
  const url = URL.createObjectURL(blob);

  return (
    <object data={url} type={pdfContentType} width="100%" height="100%">
      Medical Record document
    </object>
  );
}

function createRTFViewer(data) {
  return (
    <EditorProvider>
      <Editor
        value={data}
        disabled={true}
        containerProps={{ style: { height: "100%", overflow: "auto" } }}
      />
    </EditorProvider>
  );
}

export const DocumentViewer = memo(({ note, data, onClose }) => {
  const { dataSorted, headerConfig } = useSortableData(
    note ? [note] : [],
    noteConfig
  );

  if (!note) return null;

  let content = "XHTML".includes(note.subtype)
    ? sanitizeHTML(data)
    : note.subtype.includes("PDF")
    ? createPDFViewer(data)
    : note.subtype.includes("IMAGE")
    ? createImage(data)
    : note.subtype === "RTF"
    ? createRTFViewer(note.decoded)
    : null;

  return (
    <div className="border rounded w-full">
      <div className="w-full px-2.5 py-2 border border-b-2 border-gray-300 bg-white shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex-i justify-between">
        <Table dataSource={dataSorted} config={headerConfig} />
        <RepoTooltip label="Close document">
          <Button outline onClick={onClose}>
            <CloseIcon size={22} color="gray" />
          </Button>
        </RepoTooltip>
      </div>
      <div className="h-full">
        <Card
          sx={{
            height: "90vh",
            padding: 10,
            paddingRight: 20,
            paddingBottom: 16,
          }}
        >
          {content}
        </Card>
      </div>
    </div>
  );
});

const noteConfig = [
  {
    label: "Filler No.",
    render: (note) => note.fillerNo,
  },
  {
    label: "Service ID",
    render: (note) => note.serviceId,
  },
  {
    label: "Observer",
    render: (note) => note.observer,
  },
  {
    label: "Observation",
    render: (note) => note.observationId,
  },
  {
    label: "Subtype",
    render: (note) => note.subtype,
    align: "text-center",
  },
  {
    label: "Encoding",
    render: (note) => note.encoding,
  },
  {
    label: "Status",
    render: (note) => note.status,
  },
];
