import { useCallback, useMemo, useState } from "react";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdStream as SlIcon } from "react-icons/md";
import { TbArrowBackUpDouble as BackIcon } from "react-icons/tb";
import { Button, IconButton, StackedAvatar } from "../../components";
import QueryFilter, { FilterPanel } from "../../components/filters/QueryFilter";
import { useCommon, useFilter, useTable } from "../../store/hooks";
import { LocationDetails } from "./LocationDetails";

const LocationList = ({ onLocationChange }) => {
  const [locationId, setLocationid] = useState(null);
  const [dispatch, client] = useFilter("locations");
  const { feeds } = useCommon();

  const feedOptions = useMemo(
    () => Object.values(feeds || {}).map(({ serviceline }) => serviceline),
    [feeds]
  );

  const handleLocationChange = useCallback(
    ({ locationId, location }) => {
      if (locationId) {
        setLocationid(locationId);
        onLocationChange({ title: location, subtitle: locationId });
      } else {
        setLocationid(null);
        onLocationChange();
      }
    },
    [onLocationChange]
  );

  const locationConfig = useMemo(
    () => [
      {
        label: "Location Id",
        render: (l) => l.locationId,
        align: "text-center",
      },
      {
        label: "Facility",
        render: (l) => l.location,
        sortValue: (l) => l.location,
      },
      {
        label: "ecode",
        render: (l) => l.ecode,
        sortValue: (l) => l.ecode,
      },
      {
        label: "Exchange Count",
        render: (l) => <span>{l.types}</span>,
        align: "align-middle text-center py-2",
      },
      {
        label: "Service Lines",
        render: (l) => {
          return (
            <section className="py-1">
              <StackedAvatar
                labels={l.subservices ? l.subservices.split(", ") : []}
              />
            </section>
          );
        },
      },
      {
        key: "Action",
        render: (l) => (
          <IconButton
            title="View location details"
            icon={<Icon size={18} />}
            sx={{ margin: "5px 10px" }}
            onClick={() => handleLocationChange(l)}
          />
        ),
      },
    ],
    [handleLocationChange]
  );

  const { table } = useTable(
    client,
    locationConfig,
    dispatch,
    <FilterPanel>
      <QueryFilter
        id="locname-filter"
        label="Servicing Facility"
        dispatch={dispatch}
      />
      <QueryFilter
        type="select"
        id="sl-filter"
        label="service line"
        icon={SlIcon}
        options={{
          field: "service",
          id: "sfilter-sl-form",
          title: "Filter by service line",
          subtitle: "Find location that has a specific line of service.",
          values: feedOptions,
        }}
        dispatch={dispatch}
      />
    </FilterPanel>
  );

  if (locationId)
    return (
      <section className="flex-ij-col">
        <Button
          outline
          sx={{ width: 120 }}
          onClick={() => handleLocationChange({})}
        >
          <div className="flex space-x-1 items-center">
            <BackIcon size={20} />
            <span>Return to list</span>
          </div>
        </Button>
        <LocationDetails id={locationId} />
      </section>
    );

  return table;
};

export default LocationList;
