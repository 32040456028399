const Switcher = ({ activeIndex, children }) => {
  return children.map((route, index) => {
    return (
      <section
        key={`route-${index}`}
        className={activeIndex === index ? "h-full" : "hidden"}
      >
        {route}
      </section>
    );
  });
};

const SwitchRoute = ({ children, element }) => {
  return children || element;
};

export default Switcher;
export { SwitchRoute };
