import { memo, useEffect, useReducer } from "react";
import { MdNavigateNext as NextIcon } from "react-icons/md";
import { RiEditBoxLine as EditIcon } from "react-icons/ri";
import { Button, Divider } from "../../../components";
import { createRadioGroup } from "../../../form/FormCreate";
import { Urls } from "../../../store/axios";
import {
  useAlert,
  useAuth,
  useForm,
  useUserRequest,
} from "../../../store/hooks";
import { PolicySection } from "./PolicySection";

const roleInitState = {
  err: null,
  editMode: false,
  loading: false,
  categories: null,
  toggles: {},
};

const roleReducer = (state, action) => {
  switch (action.type) {
    case "loader":
      return { ...state, loading: action.payload, err: null };
    case "error":
      return { ...state, err: action.payload || null };
    case "toggles":
      return { ...state, toggles: action.payload };
    case "edit":
      return { ...state, editMode: action.paylod };
    case "editting":
      return { ...state, editMode: true };
    case "fetched":
      const { categories, toggles, loading } = action.payload;
      return {
        ...state,
        toggles,
        categories,
        loading,
      };
    default:
      return state;
  }
};

const fetchActionsAsync = async (role, dispatch, axiosPrivate) => {
  const data = await axiosPrivate(`${Urls.actions}/${role}`);
  const [curr, toggles] = [{}, {}];

  for (let action of data?.data || []) {
    (curr[action.category] = curr[action.category] || []).push(action);
    toggles[action.actionId] = {
      level: action.accessLevel.id,
      user_action: action.action,
      category: action.category,
      allowed: true,
    };
  }

  dispatch({
    type: "fetched",
    payload: { categories: curr, toggles, loading: false },
  });
};

export const RoleCreate = memo(() => {
  const [roleState, dispatch] = useReducer(roleReducer, roleInitState);
  const { repoAlert, setAlertMessage } = useAlert("rolecreate-alert");
  const { userRequest, apiClient, onNext, onPrevious } = useUserRequest();
  const { isAdmin } = useAuth();

  // restrict user creation
  const roleConfig = createConfig(isAdmin);
  const { renderForm, isFormValid } = useForm(roleConfig);

  const {
    newuserrole: {
      radios: { superuser, admin, superadmin },
      value: role,
    },
  } = renderForm();

  useEffect(() => {
    if (!role) return;

    dispatch({ type: "loader", payload: { loading: true } });
    void fetchActionsAsync(role, dispatch, apiClient);
    // eslint-disable-next-line
  }, [role]);

  const handleRoleSubmit = async (event) => {
    event.preventDefault();
    const formValid = await isFormValid();
    if (formValid) {
      userRequest.setRole(role);
      userRequest.permissions = roleState.toggles;
      onNext();
    } else
      setAlertMessage(
        "User has not been assigned a security role. Please assign one!",
        true
      );
  };

  const descriptions = [
    {
      label: "SuperUser",
      control: superuser,
      details: "No Administrator access.",
    },
    {
      label: "Admin",
      control: admin,
      details:
        "Allows users to make changes. " +
        "Granted rights allow to create, delete, and modify settings.",
    },
    {
      label: "SuperAdmin",
      control: superadmin,
      details:
        "Has access to the full set of admin permissions as well as " +
        "permissions to set global settings.",
    },
  ];

  return (
    <>
      {repoAlert}
      {roleState.editMode ? (
        <PolicySection
          role={role}
          selection={roleState}
          onChange={(toggles) =>
            dispatch({ type: "toggles", payload: toggles })
          }
          onBack={() => dispatch({ type: "edit", payload: false })}
        />
      ) : (
        <form onSubmit={handleRoleSubmit} className="drawer-section">
          <div className="userrole">
            <p className="title">Choose the role to assign for this user!</p>

            <div className="roles">
              {descriptions.map((item) => {
                const isCurrRole = role === item.label;

                return (
                  <div key={item.label} className="role">
                    <div className="control">{item.control}</div>
                    <div className="w-2/3">
                      <div className={`details ${isCurrRole ? "active" : ""}`}>
                        <p className="font-semibold">{item.label}</p>
                        <p className="py-1 leading-tight">{item.details}</p>
                      </div>
                      {isCurrRole && (
                        <div className="editbtn">
                          <div>
                            <Button
                              outline
                              onClick={() => dispatch({ type: "editting" })}
                              loading={roleState.loading}
                            >
                              <div className="edit">
                                <span>Edit Policies</span>
                                <EditIcon size={18} />
                              </div>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flexj space-x-2 mb-5">
            <Button onClick={onPrevious}>
              <div className="stepbtn">
                <span className="-scale-x-100">
                  <NextIcon size={20} />
                </span>
                <span className="label pr-1">PREVIOUS</span>
              </div>
            </Button>
            <Divider />
            <Button type="submit">
              <div className="stepbtn">
                <span className="label pl-1.5">NEXT</span>
                <span>
                  <NextIcon size={20} />
                </span>
              </div>
            </Button>
          </div>
        </form>
      )}
    </>
  );
});

function createConfig(enabled) {
  return {
    newuserrole: {
      type: "radio-group",
      required: true,
      ...createRadioGroup({
        name: "newuserrole",
        group: {
          superuser: {
            id: "superuser",
            value: "SuperUser",
            label: "SuperUser",
          },
          admin: {
            id: "admin",
            value: "Admin",
            label: "Admin",
          },
          superadmin: {
            id: "superadmin",
            value: "SuperAdmin",
            label: "SuperAdmin",
            disabled: !enabled,
          },
        },
      }),
    },
  };
}
