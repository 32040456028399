import { memo, useCallback, useState } from "react";
import { ImUserPlus as NewIcon } from "react-icons/im";
import { MdSearch } from "react-icons/md";
import { generatePath } from "react-router-dom";
import {
  Card,
  GroupInput,
  IconButton,
  PaginationNav,
  RepoTooltip,
} from "../../components";
import { Urls } from "../../store/axios";
import { UserRequestProvider } from "../../store/contexts/userrequest-context";
import {
  useAuth,
  useDrawer,
  useFilter,
  useNavigateHistory,
  useTable,
} from "../../store/hooks";
import NewUserForm from "./NewUserForm";
import { createListConfig } from "./interactive";

const queryKey = "users";

const UserList = () => {
  const { goTo } = useNavigateHistory();
  const [dialog, setDialog] = useState({});
  const [dispatch, client] = useFilter(queryKey);
  const {
    auth: { publicId },
  } = useAuth();
  const { drawer, showWindow, closeWindow } = useDrawer(
    "userportal",
    dialog,
    () => setDialog({})
  );

  const handleChange = useCallback(
    (name) => {
      dispatch({
        type: "filter",
        payload: { field: "term", operator: "contains", value: name },
      });
    },
    [dispatch]
  );

  const handleClose = useCallback(
    (success = false) => {
      closeWindow();
      if (success)
        client.invalidateQueries({ queryKey: [queryKey], exact: true });
    },
    // eslint-disable-next-line
    [closeWindow]
  );

  const handleAddUserClick = useCallback(() => {
    setDialog({
      title: "Create New User",
      content: (
        <UserRequestProvider>
          <NewUserForm onClose={handleClose} />
        </UserRequestProvider>
      ),
    });
    showWindow();
  }, [handleClose, showWindow]);

  const handleViewProfile = useCallback(
    (id) => {
      let _userId = publicId === id ? publicId : id;
      const url = generatePath(Urls.profile.view, { id: _userId });
      goTo(url);
    },
    [publicId, goTo]
  );

  const { table } = useTable(client, createListConfig(handleViewProfile));

  return (
    <>
      <Card
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: 24,
        }}
      >
        <section className="flex-ij space-x-2 px-2">
          <PaginationNav title="Users" config={{ totalCount: client.total }} />

          <span className="user" onClick={handleAddUserClick}>
            <RepoTooltip position="right" label="Add user">
              <IconButton icon={<NewIcon size={20} />} />
            </RepoTooltip>
          </span>
        </section>
        <div className="search mr-5">
          <GroupInput
            id="usersearch-input"
            width={260}
            lead={{ icon: <MdSearch size={20} color="gray" /> }}
            placeholder="Search user by name"
            onChange={handleChange}
          />
        </div>
      </Card>

      <div className="users">
        <div className="w-full">{table}</div>
      </div>

      {drawer}
    </>
  );
};

export default memo(UserList);
