import { useCallback, useEffect, useMemo, useState } from "react";
import { Button, Dropdown, Option, Panel, PanelBody } from "../../components";
import { createInput, createRadioGroup } from "../../form/FormCreate";
import { Urls } from "../../store/axios";
import { useAxiosPrivate, useForm, useReport } from "../../store/hooks";

export const InventoryReports = () => {
  const axiosPrivate = useAxiosPrivate();
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [isDlDisabled, setIsDlDisabled] = useState(true);
  const { client, xportControl } = useReport();

  const reportsConfig = useMemo(
    () => ({
      invradios: {
        type: "radio-group",
        required: true,
        ...createRadioGroup({
          name: "inventorygrp",
          group: {
            summary: {
              id: "summary",
              value: "summary",
              label: "Inventory Summary",
            },
            orgs: {
              id: "orgs",
              value: "orgs",
              label: "Managing Organizations",
            },
          },
        }),
      },
      rptName: {
        type: "input",
        ...createInput({
          id: "rptName",
          label: "Report Name",
          helperText: "Example: inventory-summary.csv",
        }),
        required: true,
      },
    }),
    []
  );

  const { renderForm, resetForm } = useForm(reportsConfig);
  const { invradios, rptName } = renderForm();

  const { radios: controls, value: selection } = invradios;
  const name = rptName?.props?.value;

  useEffect(() => {
    const enabled = !!selection && /^[\w\s-]+\.csv/.test(name);
    setIsDlDisabled(!enabled);
    setIsStatusDisabled(selection === "orgs");
  }, [name, selection]);

  const handleDownload = useCallback(async () => {
    try {
      setLoading(true);

      let _status = status || "all";
      const filename = `${_status.toLowerCase()}-${name}`;

      const { data } = await client.fetchQuery({
        queryKey: ["inventory-rpts", selection, _status],
        queryFn: async () =>
          await axiosPrivate.post(Urls.reports.feeds, {
            selection,
            status: _status,
            filename,
          }),
      });

      if (selection === "summary") xportControl.exportCSV(data, filename);
      else xportControl.downloadFile(data, filename, "text/csv");

      resetForm();
    } catch (error) {
      console.log(error);
    } finally {
      setStatus("");
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [name, selection, status]);

  return (
    <div className="h-full w-full">
      <div className="stepper">
        <ul>
          {["first", "between", "last"].map((name, i) => (
            <li key={name}>
              <div className={name}>
                <span>{i + 1}</span>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="flex space-x-4 h-full">
        <div className="w-[30%]">
          <Panel>
            <PanelBody>
              <p className="font-semibold mb-2 pb-2 text-[15px]">
                Select Report
              </p>
              <ol className="flex flex-col space-y-1">
                {Object.entries(controls).map(([key, radio]) => (
                  <li key={key}>{radio}</li>
                ))}
              </ol>
            </PanelBody>
          </Panel>
        </div>

        <div className="w-[50%] h-full">
          <Panel>
            <PanelBody>
              <p className="font-semibold mb-2 pb-2 text-[15px]">
                Customize Your Report
              </p>
              <div className="h-40 space-y-3">
                {rptName}
                <div className="ml-1.5">
                  <Dropdown
                    id="reportstatus"
                    name="rptstatus"
                    value={status}
                    helperText="Active Status"
                    disabled={isStatusDisabled}
                    onChange={(sel) => setStatus(sel)}
                  >
                    {["All", "Active", "Inactive"].map((option) => {
                      return (
                        <Option
                          key={`rptstatus-${option}`}
                          value={option}
                          selected={status === option}
                        />
                      );
                    })}
                  </Dropdown>
                </div>
              </div>
            </PanelBody>
          </Panel>
        </div>

        <div className="w-[20%] p-4">
          <p className="font-semibold text-[15px] mb-1">Generate Report</p>
          <Button
            onClick={handleDownload}
            disabled={isDlDisabled}
            loading={loading}
          >
            <span className="w-44">Download Report</span>
          </Button>
        </div>
      </div>
    </div>
  );
};
