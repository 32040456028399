import { useCallback, useState } from "react";
import {
  Card,
  Dropdown,
  LoadingOverlay,
  Option,
  PaginationNav,
  Table,
} from "../../components";
import useSortableData from "./use-sortable";

const useTable = (client, config, dispatch) => {
  const { total, results, isLoading } = client;
  const [limit, setLimit] = useState(24);
  const [page, setPage] = useState(1);
  const { dataSorted, headerConfig } = useSortableData(results, config || []);

  const handleChange = useCallback(
    (type, value) => {
      if (type === "page") {
        setPage(value);
        dispatch({ type, payload: value });
      } else {
        setLimit(value);
        dispatch({ type, payload: { limit: value, totalCount: total } });
      }
    },
    [dispatch, total]
  );

  const table = (
    <main>
      <LoadingOverlay active={isLoading} spinner text="Loading...">
        <Table config={headerConfig} dataSource={dataSorted} />
      </LoadingOverlay>
      <Card>
        <div className="paginate">
          <div className="page-entry">
            <Dropdown
              id="pagentry"
              top
              outline
              value={limit}
              onChange={(sel) => handleChange("limit", sel)}
            >
              <Option key="pg-12" value={12} />
              <Option key="pg-24" value={24} />
              <Option key="pg-48" value={48} />
            </Dropdown>
            <span className="tsnw tt">/page</span>
          </div>
          <PaginationNav
            display
            onChange={(page) => handleChange("page", page)}
            config={{
              totalCount: total || 0,
              currPage: page,
              pageSize: limit,
            }}
          />
        </div>
      </Card>
    </main>
  );

  return { table };
};

export default useTable;
