const position = { x: 0, y: 0 };
const letters = "abcdefghijklmnopqrstuvwxyz";

const nodeStyle = {
  style: { width: "auto" },
  position,
};

function createEdge(source, target) {
  return {
    id: `${source.id}-${target.id}`,
    source: source.id,
    target: target.id,
  };
}

export function facilityNodes(element, selection) {
  const originNode = {
    id: "origin",
    type: "input",
    data: { label: element.location?.name },
    ...nodeStyle,
  };

  const edges = [];
  const nodes = [originNode];

  Object.entries(element.feeds).forEach(([org, sources], index) => {
    const orgNode = {
      id: `org${index}`,
      data: { label: org },
      style: { Background: "rgba(255, 0, 0, 0.2)" },
    };

    const sourcesNode = {
      id: `${orgNode.id}${letters.charAt(index)}`,
      type: "sNode",
      data: { sources, selection },
      ...nodeStyle,
    };

    nodes.push(orgNode);
    nodes.push(sourcesNode);
    edges.push(createEdge(originNode, orgNode));
    edges.push(createEdge(orgNode, sourcesNode));
  });

  return [nodes, edges];
}
