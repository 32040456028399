import { Button } from "../../components";

export function SubmitButton({ enabled }) {
  return (
    <Button
      type="submit"
      content="Apply Filter"
      disabled={!enabled}
      sx={{ marginLeft: -4, marginRight: 4 }}
    />
  );
}
