import { memo, useMemo, useState } from "react";
import { HiOutlineDotsHorizontal as Icon } from "react-icons/hi";
import { MdCodeOff as OffIcon, MdGraphicEq as OnIcon } from "react-icons/md";
import { TbSettingsCheck as CheckIcon } from "react-icons/tb";
import { IconButton, Indicator } from "../../components";
import QueryFilter, { FilterPanel } from "../../components/filters/QueryFilter";
import { useCommon, useDrawer, useFilter, useTable } from "../../store/hooks";
import { ChannelSummary } from "./ChannelSummary";

const ChannelList = () => {
  const [dispatch, client] = useFilter("channels");
  const [current, setCurrent] = useState(null);
  const { sources } = useCommon();
  const sourceOptions = useMemo(
    () => ({
      field: "connector",
      id: "filter-conn-form",
      title: "Source Connectors",
      values: sources?.map((s) => s.alias),
    }),
    [sources]
  );

  const { drawer, showWindow } = useDrawer(
    "channels-portal",
    { content: <ChannelSummary channel={current} /> },
    () => setCurrent(null)
  );

  const channelsConfig = useMemo(
    () => [
      {
        key: "activecol",
        render: (channel) =>
          channel.is_enabled ? (
            <div className="flex-ij ml-2">
              <div className="channelcol">
                <OnIcon size={20} />
              </div>
            </div>
          ) : (
            <div className="flex-ij">
              <OffIcon size={20} />
            </div>
          ),
      },
      {
        label: "Channel",
        render: (channel) => (
          <div className="fcoly25">
            <span className="text-sm text-gray-500">{channel.uuid}</span>
            <span>{channel.name}</span>
          </div>
        ),
        sortValue: (channel) => channel.name,
      },
      {
        label: "Connector",
        render: (channel) => (
          <div className="fcoly25">
            <span className="text-sm text-gray-500">
              {channel.dataformat.name}
            </span>
            <span>{channel.source_connector}</span>
          </div>
        ),
      },
      {
        label: "Server",
        render: (channel) => channel.mirthserver.name,
      },
      {
        label: "Enabled",
        render: (channel) => (
          <div className="flex-ij">
            <Indicator color={channel.is_enabled && "ok"} />
          </div>
        ),
        align: "text-center",
      },
      {
        key: "channelview",
        render: (channel) => (
          <IconButton
            icon={<Icon size={18} />}
            sx={{ paddingRight: 16 }}
            title="View Channel Summary"
            onClick={() => {
              setCurrent(channel);
              showWindow();
            }}
          />
        ),
      },
    ],
    [showWindow]
  );

  const { table } = useTable(client, channelsConfig, dispatch);

  return (
    <>
      <FilterPanel>
        <QueryFilter id="chfilter" label="Channel Name" dispatch={dispatch} />
        <QueryFilter
          type="check"
          label="connector"
          icon={CheckIcon}
          options={sourceOptions}
          dispatch={dispatch}
        />
      </FilterPanel>
      {table}
      {drawer}
    </>
  );
};

export default memo(ChannelList);
