import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const initialReplace = { replace: true };

const useNavigateHistory = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const goTo = useCallback(
    (pathname, replace = false) => navigate(pathname, { replace }),
    // eslint-disable-next-line
    []
  );

  /**
   * Navigate back in history.
   * @param location {String}
   */
  const goBack = useCallback(
    (location = null) => {
      navigate(location || -1, initialReplace);
    },
    // eslint-disable-next-line
    []
  );

  const loginRedirect = useCallback(
    () =>
      navigate("/expired", {
        state: { from: location | "/dashboard" },
        replace: true,
      }),
    // eslint-disable-next-line
    []
  );

  return { goBack, goTo, loginRedirect };
};

export default useNavigateHistory;
