import { useCallback } from "react";
import { MdOutlineNotificationsNone as NotifyIcon } from "react-icons/md";
import { PiUserDuotone as UserIcon } from "react-icons/pi";
import {
  IconAvatar,
  IconButton,
  Indicator,
  RepoTooltip,
} from "../../components";
import { useCommon, useDropContainer } from "../../store/hooks";
import { MenuOption } from "./MenuOption";
import { NotifyOption } from "./NotifyOption";

const Topbar = () => {
  const { hasNotifications, removeNotification } = useCommon();

  const [menuDroparea, menuRef, showMenu, closeMenu] =
    useDropContainer("menuoption");
  const [notifyDroparea, notifyRef, showNotify, closeNotify] =
    useDropContainer("notify");

  const handleOpenMenu = useCallback(() => {
    showMenu({
      content: <MenuOption onClose={closeMenu} />,
    });
  }, [showMenu, closeMenu]);

  const handleOpenNotification = useCallback(() => {
    showNotify({
      content: (
        <NotifyOption onClose={closeNotify} onRemove={removeNotification} />
      ),
    });
  }, [showNotify, closeNotify, removeNotification]);

  return (
    <div className="topbar z-50">
      <div className="header">
        <div>{/* left side of the top bar */}</div>

        <div className="flex-i space-x-1 relative">
          <div id="notifyoption" ref={notifyRef}>
            <RepoTooltip label="Notifications" position="bottom">
              <section
                className="cursor-pointer"
                onClick={handleOpenNotification}
              >
                <IconButton
                  icon={<NotifyIcon size={22} />}
                  sx={{ padding: 4 }}
                />
                {hasNotifications ? (
                  <div className="absolute top-0 right-0 border border-white mt-1 mr-0.5">
                    <Indicator color="danger" />
                  </div>
                ) : null}
              </section>
            </RepoTooltip>
            {notifyDroparea}
          </div>

          <div
            ref={menuRef}
            id="menuoption"
            className="relative cursor-pointer"
          >
            <IconAvatar
              Icon={UserIcon}
              size={18}
              color="darkblue"
              onClick={handleOpenMenu}
            />
            {menuDroparea}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Topbar;
