import { MdOpenInNew } from "react-icons/md";
import { RiUser6Fill as UserIcon } from "react-icons/ri";
import {
  IconAvatar,
  IconButton,
  Indicator,
  RepoTooltip,
} from "../../../components";
import { createCheck, createInput } from "../../../form/FormCreate";
import { phone, required } from "../../../form/validation-rules";

export function createConfig(handleFullname) {
  return {
    mustChange: {
      type: "check",
      ...createCheck({
        id: "mustChange",
        label: "Requrie password change on first login",
      }),
      checked: true,
      valid: true,
      value: true,
    },
    given: {
      type: "input",
      ...createInput({
        id: "given",
        name: "userdetails-given",
        width: 200,
        helperText: "Given Names",
        outline: true,
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("Given Names")],
      onBlur: (value) => handleFullname("given", value),
    },
    family: {
      type: "input",
      ...createInput({
        id: "family",
        name: "userdetails-family",
        helperText: "Last Name",
        outline: true,
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("Family Name")],
      onBlur: (value) => handleFullname("family", value),
    },
    phone: {
      type: "phone",
      ...createInput({
        id: "phone",
        name: "userdetails-phone",
        label: "Phone Number",
        helperText: "Home, office, or mobile only",
      }),
      validateOnBlur: true,
      extraRules: [phone("User Phone Number")],
    },
    usertitle: {
      type: "input",
      ...createInput({
        id: "usertitle",
        name: "usertitle-dept",
        label: "Title",
        helperText: "Department Title",
      }),
      valid: true,
    },
    department: {
      type: "input",
      ...createInput({
        id: "department",
        name: "userdetails-dept",
        label: "IT Department",
        helperText: "Full department name",
      }),
      required: true,
      validateOnBlur: true,
      validationRules: [required("IT Department")],
    },
  };
}

export function createListConfig(onViewProfile) {
  return [
    {
      key: "user-select",
      render: (user) => {
        return (
          <div>
            <RepoTooltip label="Open" position="right">
              <IconButton
                icon={
                  <MdOpenInNew
                    color="primary"
                    size={20}
                    onClick={() => onViewProfile(user.publicId)}
                  />
                }
              />
            </RepoTooltip>
          </div>
        );
      },
      align: "align-center",
    },
    {
      label: "Display Name",
      render: (user) => (
        <div className="flex space-x-2 items-center py-2">
          <IconAvatar Icon={UserIcon} size={20} color="purple" />
          <div>
            <p>{user.display}</p>
            <p className="sm-text">{user.email}</p>
          </div>
        </div>
      ),
      sortValue: (user) => user.display,
    },
    {
      label: "Department",
      render: (user) => (
        <span className="tracking-tight ml-1">{user.department}</span>
      ),
      sortValue: (user) => user.department,
    },
    {
      label: "User Role",
      render: (user) => user.role,
      sortValue: (user) => user.role,
    },
    {
      label: "Status",
      render: (user) => (
        <div className="flex-j">
          <Indicator color={user.status === "A" ? "ok" : "danger"} />
        </div>
      ),
      align: "text-center",
    },
    {
      label: "Last Active On",
      render: (user) => <span className="ml-1">{user.lastLogin}</span>,
      sortValue: (user) => user.lastLogin,
    },
  ];
}
