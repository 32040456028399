import {
  createStyledLabel,
  edgeType,
  margin,
} from "../../store/hooks/use-dagre";

const position = { x: 0, y: 0 };

export function feedNodes(element) {
  const nodes = [];
  const edges = [];
  const widths = {};

  for (let [level, neighbors] of Object.entries(element)) {
    if (level > 0) {
      let xPos = 0;

      for (let neighbor of neighbors) {
        const [label, style, animated] = createStyledLabel(
          neighbor.node,
          neighbor.width
        );

        const newNode = {
          id: neighbor.node,
          data: { label },
          type: neighbor.isleaf ? "output" : "default",
          style,
          position: { x: xPos, y: level * (margin * 2) },
        };

        const newEdge = {
          id: `${neighbor.partof}-${neighbor.node}`,
          source: neighbor.partof,
          target: neighbor.node,
          type: edgeType,
          animated,
        };

        nodes.push(newNode);
        edges.push(newEdge);

        xPos += style.width + margin;
        widths[neighbor.partof] =
          (widths[neighbor.partof] || 0) + xPos - margin;
      }
    } else {
      nodes.push({
        id: neighbors[0].node,
        type: "origin",
        data: { label: neighbors[0].node },
        style: {
          width: neighbors[0].width + 40,
        },
        position,
      });
    }
  }

  return [nodes, edges, widths];
}
