import { useCallback } from "react";
import { MdFitbit as FeedIcon } from "react-icons/md";
import { Panel, PanelHeader } from "../../components";
import { useCommon } from "../../store/hooks";
import useFilter from "../../store/hooks/use-filterreducer";
import { FeedCharts } from "./FeedCharts";
import { FeedList } from "./FeedList";

const Feeds = () => {
  const { loadCommon, feedCount } = useCommon();
  const [dispatch, queryClient] = useFilter("feeds");

  const handleRefresh = useCallback(() => {
    queryClient.refetch();
    loadCommon();
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-full space-y-4">
      <Panel>
        <PanelHeader
          PanelIcon={FeedIcon}
          title="DIS Feeds"
          subtitle="Integration Services"
        />
      </Panel>
      <FeedCharts feedCount={feedCount} onRefresh={handleRefresh} />
      <FeedList dispatch={dispatch} client={queryClient || {}} />
    </div>
  );
};

export default Feeds;
