import { memo } from "react";
import { BsFillSendArrowDownFill as UnidentifiedIcon } from "react-icons/bs";
import {
  MdBiotech as DistinctIcon,
  MdOutlineSpoke as ServiceIcon,
} from "react-icons/md";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { Panel, PanelBody, PanelHeader } from "../../components";
import { useCommon } from "../../store/hooks";

function ServiceLabel({ label, value, icon, selected }) {
  const Icon = icon;
  const color = selected ? "text-gray-700" : "text-primarydark";

  return (
    <div className="space-y-3 flex flex-col items-center justify-end">
      <div className="rounded-full w-24 h-24 border border-dashed border-primarydark p-4 flex-ij">
        <Icon size={40} className={`${color} opacity-90`} />
      </div>
      <div className="text-center leading-tight tracking-tight">{label}</div>
      <div
        className={`text-[18px] font-bold ${color} font-[Roboto] opacity-90`}
      >
        {value}
      </div>
    </div>
  );
}

export const ServiceCharts = memo(({ total }) => {
  const { locationFeeds, feeds, serviceCount, hospitals } = useCommon();

  return (
    <div className="flex w-full space-x-4">
      <Panel className="w-[65%]">
        <PanelHeader>
          <div className="-my-3">
            <p className="font-semibold text-neutral-700">Resource Locations</p>
            <span>
              hospital buildings, clinics, or romms which are used for
              healthcare services.
            </span>
          </div>
        </PanelHeader>
        <PanelBody>
          <div className="grid grid-cols-3 gap-4 justify-items-center content-start w-full h-auto -mt-3 pr-2">
            <ServiceLabel
              label="Locations with unidentified service lines"
              value={locationFeeds.missing}
              icon={UnidentifiedIcon}
              selected
            />
            <ServiceLabel
              label="Organizations that provide healthcare services"
              value={hospitals.locations}
              icon={DistinctIcon}
            />
            <ServiceLabel
              label="Managed or operated physical structures"
              value={total?.toLocaleString()}
              icon={ServiceIcon}
            />
          </div>
        </PanelBody>
      </Panel>

      <Panel className="w-[35%] h-[320px]">
        <PanelHeader>
          <main className="-my-3 text-lg flex space-x-1 items-center">
            <span className="font-semibold text-neutral-700">Services</span>{" "}
            <span className="font-normal">
              ({" "}
              <span className="text-red-600">
                {serviceCount?.toLocaleString()}
              </span>{" "}
              )
            </span>
          </main>
        </PanelHeader>
        <PanelBody>
          <section className="-mt-6 w-full h-full">
            <ResponsiveContainer width="100%" height="86%">
              <BarChart data={feeds} layout="horizontal">
                <CartesianGrid strokeDasharray="4 4" vertical={false} />
                <XAxis
                  interval={0}
                  type="category"
                  scale="auto"
                  dataKey={(e) => e.code}
                  tick={{ fontSize: 14, letterSpacing: "-0.025em" }}
                  tickCount={6}
                />
                <YAxis hide tickCount={6} />
                <Bar
                  animationDuration={1000}
                  barSize={24}
                  dataKey="total"
                  background={{ fill: "#eee" }}
                  fill="#413ea0"
                  label={{ position: "top", backgroundColor: "#fff" }}
                  radius={[12, 12, 12, 12]}
                  minPointSize={5}
                />
              </BarChart>
            </ResponsiveContainer>
          </section>
        </PanelBody>
      </Panel>
    </div>
  );
});
