import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from "recharts";
import {
  Button,
  Panel,
  PanelBody,
  PanelHeader,
  Progressbar,
  Skeleton,
} from "../../components";
import { formatUtils } from "../../utils";

const COLORS = [
  "#969DAB",
  "#007BFF",
  "#FF8042",
  "#6F42C1",
  "#00C49F",
  "#FFD700",
  "#800080",
];

const Stats = ({ title, count }) => {
  return (
    <div className="p-3 pb-1">
      <span>{count}</span>
      <p>{title}</p>
    </div>
  );
};

const CustomPieTip = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const { payload: data } = payload[0];
    return (
      <p className="p-3 bg-white border border-dotted text-sm tracking-tight">
        {data.type || data.server}
      </p>
    );
  }
  return null;
};

// Data exchange types
export const ExchangeCard = ({ config, onNavigate }) => {
  const { serverArr, feedCount, hospitals, exchangeTypes } = config;

  let dataTypes = [];
  let total = 0;

  if (exchangeTypes && exchangeTypes.length > 0) {
    let labCount = 0;

    for (let d of exchangeTypes) {
      total += d.value;

      if (d.hl7 === "ORU") {
        labCount += d.value;
      } else {
        if (!d.hl7.trim()) d.hl7 = "***";
        dataTypes.push(d);
      }
    }

    dataTypes.push({
      type: "Lab Results",
      shortname: "Labs",
      hl7: "ORU",
      value: labCount,
    });
  }

  const renderTypes = dataTypes.map((x, i) => {
    let slice = x.value / total;
    return (
      <div key={i}>
        <div className="w-full text-[15px] tracking-tight flex items-center space-between space-x-3 mt-2">
          <div className="w-[50%]">{x.type}</div>
          <div className="w-[50%] grid grid-cols-3">
            <div className="">{x.hl7}</div>
            <div className="text-center">{x.value}</div>
            <div className="text-right pr-3">
              {formatUtils.formatPercentage({
                value: slice,
                sign: true,
                padStart: true,
              })}
            </div>
          </div>
        </div>
        <div className="w-[97%]">
          <Progressbar
            value={formatUtils.formatPercentage({
              value: slice,
            })}
          />
        </div>
      </div>
    );
  });

  return (
    <Panel className="w-[66%]">
      <PanelHeader>
        <div className="relative -m-3 pb-3 divide-neutral-400 divide-x flex space-x-4 leading-tight tracking-tight">
          <Stats title="Servers" count={serverArr.length} />
          <Stats title="Feeds" count={feedCount} />
          <Stats title="Groups" count={hospitals.groups} />
          <Stats title="Locations" count={hospitals.locations} />
          <div className="p-3 pb-1 relative w-72">
            <div className="absolute w-full">
              <span className="dash-title">Exchange Types</span>
              <p className="dash-description">
                What data (and their formats) expected to receive from external
                systems.
              </p>
            </div>
          </div>
          <Button
            outline
            content="View Reports"
            onClick={onNavigate}
            sx={{ position: "absolute", right: 0, marginTop: 4 }}
          />
        </div>
      </PanelHeader>
      <PanelBody>
        <div className="flex h-[13rem] mt-2">
          {dataTypes.length > 0 ? (
            <>
              <ResponsiveContainer width="102%" height="128%">
                <PieChart>
                  <Tooltip content={<CustomPieTip />} />
                  <Pie
                    data={serverArr}
                    outerRadius={60}
                    dataKey="count"
                    fill="#8884d8"
                  />
                  <Pie
                    data={dataTypes}
                    innerRadius={70}
                    outerRadius={100}
                    paddingAngle={3}
                    dataKey="value"
                    fill="#82ca9d"
                    label
                  >
                    {dataTypes.map((_, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                  </Pie>
                </PieChart>
              </ResponsiveContainer>

              <div className="w-full flex flex-col justify-center m-2 mr-4">
                {renderTypes}
              </div>
            </>
          ) : (
            <div className="h-full w-full flex-ij">
              <Skeleton placeholder />
            </div>
          )}
        </div>
      </PanelBody>
    </Panel>
  );
};
