import { memo, useCallback, useState } from "react";
import { BiServer as NewIcon } from "react-icons/bi";
import { Button } from "../../components";
import { Urls } from "../../store/axios";
import { useDrawer, useOnce, useRefetch } from "../../store/hooks";
import DownloadGroupsForm from "./DownloadGroupsForm";
import { ServerForm } from "./ServerForm";
import { ServerManager } from "./ServerManager";

function ServerConnect({ onConnect }) {
  return (
    <div className="h-full flex-ij-col">
      <div className="mb-5">
        <Button outline onClick={onConnect}>
          <div className="newserver">
            <span className="avatar">
              <NewIcon size={48} />
            </span>
            <span className="sbt">Add Server</span>
          </div>
        </Button>
      </div>
      <p className="tcenter">Connect a new server - it only takes</p>
      <p className="tcenter">a few seconds.</p>
    </div>
  );
}

const ServerList = () => {
  const [managers, setManagers] = useState([]);
  const { drawer, openDialog } = useDrawer("server-portal");
  const { fetchQuery, isFetching } = useRefetch("servers");

  const addServer = useCallback(
    (server) => (
      <li key={server.serverId}>
        <ServerManager
          server={server}
          onSync={() =>
            openDialog({
              title: "Mirth Client API",
              element: DownloadGroupsForm,
              serverName: null,
              serverId: null,
            })
          }
          // onUpdate={() => handleDialog("Mirth Server Settings", server)}
        />
      </li>
    ),
    [openDialog]
  );

  const handleInsert = useCallback(
    (server) =>
      setManagers((prev) => {
        const updated = [...prev];
        updated.splice(updated.length - 1, 0, addServer(server));
        return updated;
      }),
    [addServer]
  );

  useOnce(() => {
    fetchQuery("GET", Urls.servers).then(({ data, error }) => {
      if (data) {
        const servers = data.map(addServer);
        servers.push(
          <li key="newserver">
            <ServerConnect
              onConnect={() =>
                openDialog({
                  title: "Add New Server",
                  element: ServerForm,
                  onInsert: handleInsert,
                })
              }
            />
          </li>
        );
        setManagers(servers);
      }
    });
  });

  return (
    <div className="slist">
      <ul>
        {isFetching ? (
          <li>
            <div className="h-full flex-ij-col">
              <div className="newserver">
                <span className="avatar animate-bounce">
                  <NewIcon size={48} />
                </span>
              </div>
            </div>
          </li>
        ) : (
          managers
        )}
      </ul>
      {drawer}
    </div>
  );
};

export default memo(ServerList);
