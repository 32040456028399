import { useCallback, useState } from "react";
import { IoMdClose as CloseIcon } from "react-icons/io";
import { TbFilter as FilterIcon } from "react-icons/tb";
import { IconButton } from "..";
import { useDropContainer } from "../../store/hooks";
import BooleanFilter from "./BooleanFilter";
import CheckFilter from "./CheckFilter";
import { ComboFilter } from "./ComboFilter";
import { OpFilter } from "./OpFilter";

export const FilterPanel = ({ children }) => {
  return (
    <div className="flex flex-col justify-start items-start w-full">
      <section className="flex items-center space-x-2 mr-16">
        {children}
      </section>
    </div>
  );
};

/**
 * A React component for building queries/filters.
 * This component provides a simple UI to build a query (or set of filters) for a data set,
 * based on custom filters and operators.
 *
 * @param {String} type - select or query
 */
const QueryFilter = ({ id, type, label, icon, options, dispatch }) => {
  const [filter, setFilter] = useState({});
  const [droparea, divRef, showContainer, closeWindow] =
    useDropContainer("queryfilter");

  const handleBoolean = useCallback(
    (status) => {
      dispatch({ type: "status", payload: status });
      setFilter({ value: status });
      closeWindow();
    },
    [closeWindow, dispatch]
  );

  const handleFilter = useCallback(
    (payload) => {
      dispatch({ type: "filter", payload });
      setFilter(payload);
      closeWindow();
    },
    [dispatch, closeWindow]
  );

  const handleChecks = useCallback(
    (checks) => {
      const payload = [];
      for (let [key, enabled] of Object.entries(checks)) {
        if (enabled) payload.push(key);
      }

      dispatch({ type: "connector", payload });
      setFilter({ value: payload.map((val) => val.split(" ")[0]).join(", ") });
      closeWindow();
    },
    [dispatch, closeWindow]
  );

  const handleClear = useCallback(() => {
    setFilter({});
    if (type === "check") dispatch({ type: "connector", payload: [] });
    else if (type === "bool") dispatch({ type: "status" });
    else dispatch({ type: "filter", payload: { ...filter, value: "" } });
  }, [dispatch, filter, type]);

  const CurrIcon = icon || FilterIcon;
  const content =
    type === "select" ? (
      <ComboFilter id={id} {...options} onApply={handleFilter} />
    ) : type === "check" ? (
      <CheckFilter {...options} filter={filter} onApply={handleChecks} />
    ) : type === "bool" ? (
      <BooleanFilter selectedValue={filter.value} onApply={handleBoolean} />
    ) : (
      <OpFilter label={label} field="term" onApply={handleFilter} />
    );

  return (
    <div
      id={id}
      ref={divRef}
      className={`sfilter border${filter.value ? "" : "-dashed"}`}
    >
      <IconButton
        position="left"
        label={label.toLowerCase()}
        icon={<CurrIcon size={20} />}
        onClick={() => showContainer({ content })}
      />
      {droparea}
      {filter.value && (
        <div className="selection">
          <span className="mr-1">{filter.value}</span>
          <IconButton
            icon={<CloseIcon />}
            sx={{ margin: 0, padding: 0 }}
            onClick={handleClear}
          />
        </div>
      )}
    </div>
  );
};

export default QueryFilter;
